import React, { useState, useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import Header from "../../../customComponent/Header";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage } from "../../../customComponent/CustomAlertMessage";
import Swal from "sweetalert2";

const DashboardPage = () => {
    const [activeTab, setActiveTab] = useState('');
    const [maintanance, setmaintanance] = useState(false);
    const userType = sessionStorage.getItem('userType');
    const myPermission = sessionStorage.getItem('permissions');
    let permissions = Array.isArray(JSON.parse(myPermission)) ? JSON.parse(myPermission)?.map(x => x.value) : [];
    const user_Id = sessionStorage.getItem('userId');


    useEffect(() => {
        HandleCheckMaint();
        let URL = window.location.href?.split('/');
        let route = URL.pop();
        setActiveTab(route)
    }, []);

    const changeMaintenance = async (status, user_Id) => {
        await AuthService.changeMaintenance(status, user_Id).then(
            async (result) => {
                if (result.success) {
                    try {
                        HandleCheckMaint();
                    } catch (error) {
                        alertErrorMessage(error);
                    }
                } else {
                    alertErrorMessage(result.message);
                }
            }
        );
    };

    const HandleCheckMaint = async () => {
        await AuthService.getMaintenance().then(
            async (result) => {
                if (result?.success) {
                    try {
                        setmaintanance(result?.data?.maintenance)
                    } catch (error) {
                        alertErrorMessage(error);
                    }
                } else {
                    alertErrorMessage(result.message);
                }
            }
        );
    };


    const HandleWebsiteMaintance = async (maintanance) => {
        await Swal.fire({
            title: 'Do you want to save the changes?',
            icon: 'info',
            showDenyButton: true,
            confirmButtonText: 'Save',
            denyButtonText: `Don't save`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                changeMaintenance(maintanance ? false : true, user_Id)

            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'error')
            }
        })
    };

    return (
        <>
            <Header />
            <div id="layoutSidenav" >
                <div id="layoutSidenav_nav">
                    <nav className="sidenav shadow-right sidenav-light">
                        <div className="sidenav-menu">
                            <div className="nav accordion" id="accordionSidenav">

                                <div className="sidenav-menu-heading"> Website </div>

                                <div className="custom_row" >
                                    <div class="form-check form-switch">
                                        <label class="form-check-label" for="flexSwitchCheckChecked"> Website on maintenance </label>
                                        <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={maintanance} onClick={(e) => { HandleWebsiteMaintance(maintanance) }} />
                                    </div>
                                </div>


                                <div className="sidenav-menu-heading"> Admin Dashboard</div>

                                <Link to="" className="nav-link collapsed" onClick={() => setActiveTab('homepage')}>
                                    <div className="nav-link-icon"><i className="fa fa-th"></i></div>
                                    Dashboards
                                </Link>

                                {userType === '1' ?
                                    <>
                                        <div className={`nav-link collapsed ${activeTab?.includes('listsubadmin') || activeTab?.includes('addsubadmin') ? 'active' : ''}`} data-bs-toggle="collapse" data-bs-target="#collapseSubAdmin" aria-expanded="false" aria-controls="collapseSubAdmin">
                                            <div className="nav-link-icon"><i className="fa fa-user-friends"></i></div>
                                            Sub Admin
                                            <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                        </div>
                                        <div className="collapse" id="collapseSubAdmin" data-bs-parent="#accordionSidenav">
                                            <nav className="sidenav-menu-nested nav">
                                                <Link className={`nav-link  ${activeTab?.includes('listsubadmin') ? 'active' : ''}`} to="listsubadmin" onClick={() => setActiveTab('listsubadmin')}>Sub Admin List</Link>

                                                <Link className={`nav-link  ${activeTab?.includes('addsubadmin') ? 'active' : ''}`} to="addsubadmin" onClick={() => setActiveTab('addsubadmin')}>Add New</Link>
                                            </nav>
                                        </div>
                                    </>
                                    : null
                                }
                                {permissions.includes(1) || userType === '1' ?
                                    <>
                                        <div className={`nav-link collapsed ${activeTab?.includes('tradelist') ? 'active' : ''}`} data-bs-toggle="collapse" data-bs-target="#collapseTraders" aria-expanded="false" aria-controls="collapseTraders">
                                            <div className="nav-link-icon"><i className="fa fa-wave-square"></i></div>
                                            Traders
                                            <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                        </div>

                                        <div className="collapse" id="collapseTraders" data-bs-parent="#accordionSidenav">
                                            <nav className="sidenav-menu-nested nav">
                                                <Link className={`nav-link  ${activeTab?.includes('tradelist') ? 'active' : ''}`} to="tradelist" onClick={() => setActiveTab('tradelist')}>Traders List</Link>
                                                {/* <Link className="nav-link" to="" onClick={() => setActiveTab('addnewtrade')}>Add New Trader </Link> */}
                                            </nav>
                                        </div>
                                    </>
                                    : null
                                }

                                {permissions.includes(2) || userType === '1' ?
                                    <>
                                        <div className={`nav-link collapsed ${(activeTab?.includes('pendingkyc') || activeTab?.includes('approvedkyc') || activeTab?.includes('RejectedKyc')) ? 'active' : ''}`} data-bs-toggle="collapse" data-bs-target="#collapseKyc" aria-expanded="false" aria-controls="collapseKyc">
                                            <div className="nav-link-icon"><i className="fa fa-check-circle"></i></div>
                                            KYC Manager
                                            <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                        </div>

                                        <div className="collapse" id="collapseKyc" data-bs-parent="#accordionSidenav">
                                            <nav className="sidenav-menu-nested nav">

                                                <Link className={`nav-link ${activeTab?.includes('pendingkyc') ? 'active' : ''}`} to="pendingkyc" onClick={() => setActiveTab('pendingkyc')}>Pending KYC</Link>

                                                <Link className={`nav-link  ${activeTab?.includes('approvedkyc') ? 'active' : ''}`} to="approvedkyc" onClick={() => setActiveTab('approvedkyc')}>Approved KYC</Link>

                                                <Link className={`nav-link  ${activeTab?.includes('RejectedKyc') ? 'active' : ''}`} to="RejectedKyc" onClick={() => setActiveTab('RejectedKyc')}>Rejected KYC</Link>
                                            </nav>
                                        </div>
                                    </>
                                    : null
                                }
                                {permissions.includes(4) || userType === '1' ?
                                    <>
                                        <div className={`nav-link collapsed ${(activeTab?.includes('UserPendingBank') || activeTab?.includes('UserApprovedBank') || activeTab?.includes('UserRejectedBank')) ? 'active' : ''}`} data-bs-toggle="collapse" data-bs-target="#collapseBank" aria-expanded="false" aria-controls="collapseBank">
                                            <div className="nav-link-icon"><i className="fa fa-university"></i></div>
                                            User Bank Account
                                            <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                        </div>

                                        <div className="collapse" id="collapseBank" data-bs-parent="#accordionSidenav">
                                            <nav className="sidenav-menu-nested nav">
                                                <Link className={`nav-link ${activeTab?.includes('UserPendingBank') ? 'active' : ''}`} to="UserPendingBank" onClick={() => setActiveTab('UserPendingBank')}>Pending Bank Accounts</Link>
                                                <Link className={`nav-link ${activeTab?.includes('UserApprovedBank') ? 'active' : ''}`} to="UserApprovedBank" onClick={() => setActiveTab('UserApprovedBank')}>Approved Bank Accounts</Link>
                                                <Link className={`nav-link ${activeTab?.includes('UserRejectedBank') ? 'active' : ''}`} to="UserRejectedBank" onClick={() => setActiveTab('UserRejectedBank')}>Rejected Bank Accounts</Link>

                                            </nav>
                                        </div>
                                    </>
                                    : null
                                }
                                 {permissions.includes(4) || userType === '1' ?
                                    <>
                                        <div className={`nav-link collapsed ${(activeTab?.includes('UserPendingUPI') || activeTab?.includes('UserApprovedUPI') || activeTab?.includes('UserRejectedUPI')) ? 'active' : ''}`} data-bs-toggle="collapse" data-bs-target="#collapseUPI" aria-expanded="false" aria-controls="collapseUPI">
                                            <div className="nav-link-icon"><i className="fa fa-university"></i></div>
                                            User UPI Account
                                            <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                        </div>

                                        <div className="collapse" id="collapseUPI" data-bs-parent="#accordionSidenav">
                                            <nav className="sidenav-menu-nested nav">
                                                <Link className={`nav-link ${activeTab?.includes('UserPendingUPI') ? 'active' : ''}`} to="UserPendingUPI" onClick={() => setActiveTab('UserPendingUPI')}>Pending UPI Accounts</Link>
                                                <Link className={`nav-link ${activeTab?.includes('UserApprovedUPI') ? 'active' : ''}`} to="UserApprovedUPI" onClick={() => setActiveTab('UserApprovedUPI')}>Approved UPI Accounts</Link>
                                                <Link className={`nav-link ${activeTab?.includes('UserRejectedUPI') ? 'active' : ''}`} to="UserRejectedUPI" onClick={() => setActiveTab('UserRejectedUPI')}>Rejected UPI Accounts</Link>

                                            </nav>
                                        </div>
                                    </>
                                    : null
                                }



                                {permissions.includes(3) || userType === '1' ?
                                    <Link className={`nav-link collapsed ${activeTab?.includes('currencymanagement') ? 'active' : ''}`} to="currencymanagement" onClick={() => setActiveTab('currencymanagement')}>
                                        <div className="nav-link-icon"><i className="fa fa-dollar-sign"></i></div>
                                        Currency Management
                                    </Link>
                                    : null
                                }

                                {/* {userType === '1' ?
                                    <Link className="nav-link collapsed" to="" onClick={() => setActiveTab('masterwallet')}>
                                        <div className="nav-link-icon"><i class="fab fa-google-wallet"></i></div>
                                        Master Wallet
                                    </Link>
                                    : null
                                } */}

                                {permissions.includes(6) || userType === '1' ?
                                    <Link className={`nav-link collapsed ${activeTab?.includes('ExchangeWalletManagement') ? 'active' : ''}`} to="ExchangeWalletManagement" onClick={() => setActiveTab('ExchangeWalletManagement')}>
                                        <div className="nav-link-icon"><i className="fa fa-dollar-sign"></i></div>
                                        Exchange Wallet Management
                                    </Link>
                                    : null
                                }

                                {permissions.includes(5) || userType === '1' ?
                                    <Link className={`nav-link collapsed ${activeTab?.includes('currencypair') ? 'active' : ''}`} onClick={() => setActiveTab('currencypair')} to="currencypair">
                                        <div className="nav-link-icon"><i className="fa fa-prescription"></i></div>
                                        Currency Pair Management
                                    </Link>
                                    : null
                                }

                                {permissions.includes(7) || userType === '1' ?
                                    <Link className={`nav-link collapsed ${activeTab?.includes('OrderBook') ? 'active' : ''}`} to="OrderBook" onClick={() => setActiveTab('OrderBook')}>
                                        <div className="nav-link-icon"><i className="fa fa-list"></i></div>
                                        OrderBook
                                    </Link>
                                    : null
                                }
                                {/* {permissions.includes(8) || userType === '1' ?
                                    <Link className={`nav-link collapsed ${activeTab === 'TDS' ? 'active' : ''}`} to="" onClick={() => setActiveTab('TDS')}>
                                        <div className="nav-link-icon"><i className="fa fa-feather"></i></div>
                                        TDS
                                    </Link>
                                    : null
                                } */}

                                {permissions.includes(9) || userType === '1' ?
                                    <Link className={`nav-link collapsed ${activeTab?.includes('TradingCommision') ? 'active' : ''}`} to="TradingCommision" onClick={() => setActiveTab('TradingCommision')}>
                                        <div className="nav-link-icon"><i className="fa fa-list"></i></div>
                                        Trading Commision
                                    </Link>
                                    : null
                                }

                                {permissions.includes(10) || userType === '1' ?
                                    <>
                                        <div className={`nav-link collapsed ${(activeTab?.includes('fundsManagement') || activeTab?.includes('fundsDManagement')) ? 'active' : ''}`} data-bs-toggle="collapse" data-bs-target="#collapseFundsManagement" aria-expanded="false" aria-controls="collapseSubAdmin">
                                            <div className="nav-link-icon"><i className="fa fa-dollar-sign"></i></div>
                                            Funds Management
                                            <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                        </div>
                                        <div className="collapse" id="collapseFundsManagement" data-bs-parent="#accordionSidenav">
                                            <nav className="sidenav-menu-nested nav">
                                                <Link className={`nav-link collapsed ${activeTab?.includes('fundsManagement') ? 'active' : ''}`} to="fundsManagement" onClick={() => setActiveTab('fundsManagement')}>
                                                    <div className="nav-link-icon"><i className="fa fa-list"></i></div>
                                                    Withdrawal
                                                </Link>
                                                <Link className={`nav-link collapsed ${activeTab?.includes('fundsDManagement') ? 'active' : ''}`} to="fundsDManagement" onClick={() => setActiveTab('fundsDManagement')}>
                                                    <div className="nav-link-icon"><i className="fa fa-list"></i></div>
                                                    Deposit
                                                </Link>

                                            </nav>
                                        </div>
                                    </>
                                    : null
                                }

                                {permissions.includes(11) || userType === '1' ?
                                    <>
                                        <div className={`nav-link collapsed ${(activeTab?.includes('fiatManagement') || activeTab?.includes('fiatDManagement')) ? 'active' : ''}`} data-bs-toggle="collapse" data-bs-target="#collapseFiatManagement" aria-expanded="false" aria-controls="collapseSubAdmin">
                                            <div className="nav-link-icon"><i className="fa fa-feather"></i></div>
                                            Fiat Management
                                            <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                        </div>
                                        <div className="collapse" id="collapseFiatManagement" data-bs-parent="#accordionSidenav">
                                            <nav className="sidenav-menu-nested nav">
                                                <Link className={`nav-link collapsed ${activeTab?.includes('fiatManagement') ? 'active' : ''}`} to="fiatManagement" onClick={() => setActiveTab('fiatManagement')}>
                                                    <div className="nav-link-icon"><i className="fa fa-list"></i></div>
                                                    Withdrawal
                                                </Link>
                                                <Link className={`nav-link collapsed ${activeTab?.includes('fiatDManagement') ? 'active' : ''}`} to="fiatDManagement" onClick={() => setActiveTab('fiatDManagement')}>
                                                    <div className="nav-link-icon"><i className="fa fa-list"></i></div>
                                                    Deposit
                                                </Link>
                                            </nav>
                                        </div>
                                    </>
                                    : null
                                }

                                {userType === '1' ?
                                    <>
                                        <div className={`nav-link collapsed ${(activeTab?.includes('p2pCoinList') || activeTab?.includes('p2pHistory') || activeTab?.includes('p2pPaymentOptions')) ? 'active' : ''}`} data-bs-toggle="collapse" data-bs-target="#collapseP2p" aria-expanded="false" aria-controls="collapseP2p">
                                            <div className="nav-link-icon"><i className="fa fa-check-circle"></i></div>
                                            P2P Manager
                                            <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                        </div>

                                        <div className="collapse" id="collapseP2p" data-bs-parent="#accordionSidenav">
                                            <nav className="sidenav-menu-nested nav">

                                                <Link className={`nav-link ${activeTab?.includes('p2pCoinList') ? 'active' : ''}`} to="p2pCoinList" onClick={() => setActiveTab('p2pCoinList')}>Coin list</Link>

                                                <Link className={`nav-link  ${activeTab?.includes('p2pHistory') ? 'active' : ''}`} to="p2pHistory" onClick={() => setActiveTab('p2pHistory')}>P2P History</Link>

                                                {/* <Link className={`nav-link  ${activeTab?.includes('p2pPaymentOptions') ? 'active' : ''}`} to="p2pPaymentOptions" onClick={() => setActiveTab('p2pPaymentOptions')}>Payment Options</Link> */}
                                            </nav>
                                        </div>
                                    </>
                                    : null
                                }

                                {permissions.includes(12) || userType === '1' ?
                                    <Link className={`nav-link collapsed ${activeTab?.includes('notification') ? 'active' : ''}`} to="notification" onClick={() => setActiveTab('notification')}>
                                        <div className="nav-link-icon"><i className="fa fa-bell"></i></div>
                                        Notifications Management
                                    </Link>
                                    : null
                                }
                                 { userType === '1' ?
                                    <Link className={`nav-link collapsed ${activeTab?.includes('support') ? 'active' : ''}`} to="support" onClick={() => setActiveTab('support')}>
                                        <div className="nav-link-icon"><i class="ri-ticket-fill"></i></div>
                                        Support
                                    </Link>
                                    : null
                                }

                            </div>
                        </div>
                    </nav >
                </div >
                <Outlet />
            </div>
        </>
    )
}

export default DashboardPage;