import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import VerifyKyc from "../VerifyKyc";
import { ApiConfig } from "../../../api/apiConfig/ApiConfig";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import moment from "moment";
import { alertErrorMessage, } from "../../../customComponent/CustomAlertMessage";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import Papa from 'papaparse';
import 'jspdf-autotable';
import DataTableBase from "../../../customComponent/DataTable";
import "./style.css";

const PendingKyc = () => {
  const [data, setData] = useState([]);
  const [activeScreen, setActiveScreen] = useState("pending");
  const [userId, setUserId] = useState("");
  const [userKycData, setuserKycData] = useState();


  const linkFollow = (row) => {
    return (
      <button
      
        className="verifybtn"
        onClick={() => {
          setUserId(row?.userId);
          setuserKycData(row)
          setActiveScreen("detail");
        }}
      >
        View
      </button>
    );
  };


  const imageFormatter = (row) => {
    return (
      <img
        style={{ width: '40%', height: 'auto' }}
        className="table-img"
        src={ApiConfig?.uploadUrl + row?.user_selfie}
        alt="Selfie"
      />
    );
  }

  const dateFilter = (row) => {
    return <>{moment(row?.createdAt).format("Do MMMM YYYY")}</>;
  };

  const columns = [
    { name: 'ID', selector: row => row._id, },
    { name: 'Pan Number', selector: row => row.pancard_number, },
    { name: 'Email ID', selector: row => row.emailId, },
    { name: 'Date', selector: dateFilter, },
    { name: 'Selfie', selector:imageFormatter, },
    { name: 'Action', selector: linkFollow, }
  ];

  useEffect(() => {
    handleData();
  }, []);

  const handleData = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getdata().then(async (result) => {
      if (result?.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setData(result?.data.reverse());
        } catch (error) {
          alertErrorMessage(error);
          console.log("error", `${error}`);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        /* alertErrorMessage(result.message); */
      }
    });
  };


  // *********Export Trade Data In Excel Formats ************* // 
  const exportToExcel = () => {
    const exportableData = ExportableData();
    const ws = XLSX.utils.json_to_sheet(exportableData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Trades');
    XLSX.writeFile(wb, 'Pending Kyc.xlsx');
  };


  // *********Export Trade Data In PDF Formats ************* // 
  const exportToPDF = () => {
    const exportableData = ExportableData();
    const doc = new jsPDF('landscape', 'mm', 'a4');
    doc.text('Pending Kyc', 10, 10);
    const tableData = exportableData.map(item => Object.values(item));
    const tableHeaders = Object.keys(exportableData[0]); // Get keys from the first object
    doc.autoTable({
      head: [tableHeaders],
      body: tableData,
      startY: 20,
      theme: 'grid',
    });
    doc.save('Pending Kyc.pdf');
  };

  // ********* Rearrange Exportabel Data ********* //
  const ExportableData = () => {
    const modifiedArray = data.map((item, index) => {
      const { updatedAt, user_id, __v, _id, kyc_reject_reason, kycdata, createdAt, ...rest } = item;
      const modifiedItem = {};
      for (const key in rest) {
        if (Object.hasOwnProperty.call(rest, key)) {
          const modifiedKey = key.toUpperCase();
          modifiedItem[modifiedKey] = rest[key];
        }
      }
      modifiedItem.CREATED_AT = moment(createdAt).format('DD/MM/YYYY hh:mm A');
      return modifiedItem;
    });
    return modifiedArray;

  }
  // *********Export Trade Data In CSV Formats ************* // 
  const exportToCSV = () => {
    const exportableData = ExportableData();
    const csv = Papa.unparse(exportableData);
    const blob = new Blob([csv], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'Pending Kyc.csv';
    link.click();
  };
  const HandleExport = (exportData) => {
    if (exportData === 'EXCEL') {
      exportToExcel()
    }
    else if (exportData === 'PDF') {
      exportToPDF()
    }
    else if (exportData === 'CSV') {
      exportToCSV()
    }
  };

  return activeScreen === "pending" ? (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
          <div className="container-xl px-4">
            <div className="page-header-content pt-4">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto mt-4">
                  <h1 className="page-header-title">
                    <div className="page-header-icon">
                      <i className=" fa fa-user-slash"></i>
                    </div>
                    Pending Kyc
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-xl px-4 mt-n10">
          <div className="card mb-4">
            <div class="card-header">
              Pending Kyc List
              {data.length === 0 ? (
                ""
              ) : (
                <div class="dropdown">
                  <button
                    class="btn btn-dark btn-sm dropdown-toggle"
                    id="dropdownFadeInUp"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa fa-download me-3"></i>Export
                  </button>
                  <div class="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                    <button type="button" onClick={() => { HandleExport('EXCEL') }} class="dropdown-item">Export as EXCEL</button>
                    <button type="button" onClick={() => { HandleExport('CSV') }} class="dropdown-item">Export as CSV</button>
                    <button type="button" onClick={() => { HandleExport('PDF') }} class="dropdown-item">Export as PDF</button>
                  </div>
                </div>
              )}
            </div>
            <div className="card-body">
              <>
                {data.length === 0 ? (
                  <h6 className="ifnoData">
                    <img src="/assets/img/no-data.png" /> <br /> No Data
                    Available
                  </h6>
                ) : (
                  <div class="table-responsive">
                    <DataTableBase columns={columns} data={data} />
                  </div>
                )}
              </>
            </div>
          </div>
        </div>
      </main>
    </div>
  ) : (
    <VerifyKyc userId={userId} kycData={userKycData} page='PendingKyc' />
  );
};
export default PendingKyc;
