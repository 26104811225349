const appUrl = "https://api.inoutbit.com";

export const ApiConfig = {
  // =========EndPoints==========
  login: "login",
  newPassword: "newPassword",
  getdata: "pending-kyc-user",
  cpbalancebycoin: "cpbalancebycoin",
  getverifyData: "approve-kyc-user",
  getrejectedData: "rejected-kyc-user",
  getkycData: "kyc-details",
  verifyIdentity: "update-kyc-status",
  rejectIdentity: "verify-kyc",
  getusers: "getUsers",
  getTotaluser: "user-count",
  getVerified: "approved-kyc-count",
  getPanding: "pending-kyc-count",
  getSupport: "support",
  getwithdrawal: "withdrawlrequest",
  getregistration: "today_new_registration",
  allkyc: "kyc-details",
  coinlist: "coinList",
  currencyPair: "allsinglecoinlist",
  getSubAdminList: "sub-admin-list",
  AddsubAdmin: "add-new-admin",
  AddTrade: "addnewtrader",
  subadmindelete: "delete_subadmin",
  getallorder: "getOrders",
  addNotify: "add_notification",
  notificationList: "notification-list",
  deleteNotification: "delete-notification",
  helplist: "getallhelplist",
  SubadminStatus: "admin_status",
  updateSubadmin: "edit_subadmin",
  tradeStatus: "kycsetstatus",
  getInrWithrawList: "get_inr_withdrawal_history",
  userreqapprove: "confirmInrWithdraw",
  userreqreject: "rejectInrWithdraw",
  addAdTicket: "addAdTicket",
  ticketList: "ticketlist",
  adminsupport: "adminsupport",
  sendmailtouser: "sendmailtouser",
  documentchange: "documentchange",
  transferhistory: "traderinfo",
  admintransfer: "admintransfer",
  depositrequest: "depositrequest",
  withdrawlrequest: "withdrawlrequest",
  totaldepositrequest: "depositrequest",
  totalwithdrawlrequest: "getWithdrawlReq",
  getmasterbal: "getmasterbal",
  changeseen: "changeseen",
  getCoinList: "get-coin",
  walletCoinList: "get-coin",
  setcoinwithdrawal: "edit-currency",
  getcpaccountinfo: "getcpaccountinfo",
  getcpcoinbalance: "getcpcoinbalance",
  AddCoinPair: "add-pairs",
  AddPairFee: "updatecoinfee",
  getCurrencyPairList: "get-pairs",
  tredingReport: "comissionReport",
  sendFundStatus: "transactionstatus",
  getWithdrawFunds: "get_withdrawal_history",
  getDepositFunds: "get_deposit_history",
  inrdepositreq: "get_inr_deposit_history",
  confirmInrDeposit: "confirmInrDeposit",
  rejectInrDeposit: "rejectInrDeposit",
  getUserWalletList: "select_given_coin",
  userWalletTransfer: "withdrawalcoin",
  setrewardrate: "kyc/setrewardrate",
  updatetdsrate: "global_tds",
  updatemailTamplate: "updatemailTamplate",
  traderreport: "user-list",
  pendingtrader: "pendingtrader",
  fiatwithreq: "fiatwithreq",
  verifiedtrader: "verifiedtrader",
  fiatdepreq: "fiatdepreq",
  addNewCoins: "add-coin",
  getMasterWalletList: "deposit_withdrawal",
  admincoinaddress: "generate-address",
  getreceive: "getreceive",
  stakingList: "stacking/getAllUserstacking",
  transactionstatus: "transactionstatus",
  Addbanner: "add_banner",
  bannerList: "banner_list",
  updateBanner: "setbanner",
  currencyCoinList: "get-coin",
  bannerdelete: "delete-banner",
  BannerStatus: "setbannerStatus",
  deleteCoinPair: "delete-pair",
  walletStatus: "withdrawstatus",
  getOtp: "auth/getcode",
  walletTransfer: "transfertoother",
  updateStatus: "update_status",
  getMaintenance: "get-maintenance",
  changeMaintenance: "change-maintenance",
  updateWithdrawalStatus: "update_withdrawal_status",
  updateDepositStatus: "update_deposit_status",
  adminBankDetails: "get_user_bank_details",
  addBankDetails: "add_bank_details",
  editBankDetails: "edit_admin_bank_details",
  CoinCategory: "coin-category-list",
  getBankDetails: "get-bank-details",
  verifyBankDetails: "verify-bank-details",
  verifyUPIDetails:"verify-upi-details",
  fundsTransfer: "debit_credit",
  addAdminUpi: "add_admin_upi",
  adminUpiDetails: "admin_upi_details",
  tradeById: 'find-trades-by-orderid',
  OrderBook: 'orderbook',
  cancelOrder: 'cancel-order',
  tradingCommission: "trading-commission",
  pendingBankDetails: "pending-bank-details",
  pendingUPIDetails: "pending-upi-details",
  approveBankDetails: "approve-bank-details",
  approveUPIDetails: "approve-upi-details",
  rejectBankDetails: "reject-bank-details",
  rejectUPIDetails: "reject-upi-details",
  tds_report: "tds_report",
  tdsPercentage: "tds_percentage",
  perdayTds: "perday_tds",
  chainList: "chain_list",
  tdsSearch: "tds_search",
  MasterAccount: 'master-account',
  botStatus: 'bot-status',
  verifyDeposit: 'verify-deposit',
  crytoList: 'p2p_coin_list',
  fiatList : `${appUrl}/v1/p2p/fiat_currency_list`,
  paymentList : `${appUrl}/v1/p2p/payment_type_list`,
  p2pHistory : `${appUrl}/v1/p2p/all_p2p_orders`,
  paymentType : `${appUrl}/v1/p2p/admin_payments`,
  paymentTypeRemove : `${appUrl}/v1/p2p/remove_payments`,
  orderDetails: `${appUrl}/v1/p2p/order_details`,
  orderDispute: `${appUrl}/v1/p2p/dispute_handling`,
  addCurrency : "p2p_currencies",
  removeCurrency : "remove_p2p_currency",
  getAllTickets: 'get-all-tickets',
  replyTicket: "reply-ticket",
  updateTicketStatus: "update-ticket-status",

  // ============URLs================
  baseHelp: `${appUrl}/help/`,
  baseTran: `${appUrl}/v1/transaction/`,
  baseUrl: `${appUrl}/v1/admin/`,
  uploadUrl: `${appUrl}/`,
  appUrl: `${appUrl}/`,
  basenotify: `${appUrl}/acc/`,
  baseSecure: `${appUrl}/v1/coin/`,
  baseWallet: `${appUrl}/wallet/`,
  baseCoin: `${appUrl}/v1/coin/`,
  baseReport: `${appUrl}/report/`,
  uploadcurrency: `${appUrl}/`,
  baseAdmin: `${appUrl}/v1/admin/`,
  baseUser: `${appUrl}/v1/admin/`,
  baseSupport: `${appUrl}/v1/support/`,
  baseExchange: `${appUrl}/v1/exchange/`,
};
