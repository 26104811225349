import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, } from "../../../customComponent/CustomAlertMessage";
import moment from "moment";
import { CSVLink } from "react-csv";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import DataTable from "react-data-table-component";

const TradingCommision = () => {
  const [TradingCommission, setTradingCommission] = useState([]);
  const [allData, setallData] = useState([]);

  const [totalCount, setTotalCount] = useState(0);
  const [skipCount, setSkipCount] = useState(0);

  const dateFormatter = (row) => {
    return <>{moment(row?.createdAt).format("MMM Do YYYY")}</>;
  };

  const serialNo = (skipCount) => (row, rowIndex) => {
    return (
      <>
        {rowIndex + 1 + skipCount}
      </>
    );
  };
  const columns = [
    { name: 'Serial no.', selector: serialNo(skipCount), },
    { name: "Date", sort: true, selector: dateFormatter, },
    { name: "Name", sort: true, selector: row => row.short_name, },
    { name: "Fee", sort: true, selector: row => row.fee, },
    { name: "Fee Type", sort: true, selector: row => row.fee_type, },
    { name: "From User", sort: true, selector: row => row.from_user, },
    { name: "Percentage", sort: true, selector: row => row.percentage, },
    { name: "From User", sort: true, selector: row => row.from_user, },
    { name: "Amount", sort: true, selector: row => row.amount, },
  ];



  useEffect(() => {
    tradingCommission(0);
  }, []);

  function roundUpToNearestMultipleOf10(number) {
    const remainder = number % 10;
    const differenceToNextMultiple = 10 - remainder;
    const roundedNumber = remainder === 0 ? number : number + differenceToNextMultiple;

    return roundedNumber;
  }

  const tradingCommission = async (skip) => {
    LoaderHelper.loaderStatus(true);
    await AuthService?.tradingCommission(skip, 0).then(async (result) => {
      LoaderHelper.loaderStatus(false);
      if (result?.success) {
        try {
          setTradingCommission(result?.data?.reverse());
          setallData(result?.data);
          setSkipCount(skip);
          let count = roundUpToNearestMultipleOf10(result?.data?.count)
          setTotalCount(count)
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage("Something Went Wrong");
      }
    });
  };
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const filterDate = () => {
    const filteredData = allData.filter((item) => {
      const createdAtDate = new Date(item.createdAt);
      return (
        (!fromDate || createdAtDate >= new Date(fromDate)) &&
        (!toDate || createdAtDate <= new Date(toDate))
      );
    });
    setTradingCommission(filteredData?.reverse())
  }
  const ResetfilterDate = () => {
    setFromDate('')
    setToDate('')
    setTradingCommission(allData)
  }
  return (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
          <div className="container-xl px-4">
            <div className="page-header-content pt-4">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto mt-4">
                  <h1 className="page-header-title">
                    <div className="page-header-icon">
                      <i className="fa fa-dollar-sign"></i>
                    </div>
                    Trading Commission
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* Main page content */}
        <div className="container-xl px-4 mt-n10">
          <div className="card mb-4">
            <div class="card-header">
              Trading Commission
              {TradingCommission.length === 0 ? (
                ""
              ) : (
                <div class="dropdown">
                  <button
                    class="btn btn-dark btn-sm dropdown-toggle"
                    id="dropdownFadeInUp"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Export{" "}
                  </button>
                  <div
                    class="dropdown-menu animated--fade-in-up"
                    aria-labelledby="dropdownFadeInUp"
                  >
                    <CSVLink data={TradingCommission} class="dropdown-item">
                      Export as CSV
                    </CSVLink>
                  </div>
                </div>
              )}
            </div>
            <>

              <div class="table-responsive">
                {TradingCommission.length === 0 ? (
                  <h6 className="ifnoData">
                    <img src="/assets/img/no-data.png" /> <br /> No Data
                    Available
                  </h6>
                ) : (
                  <DataTable direction="auto" responsive subHeaderAlign="right" subHeaderWrap striped highlightOnHover fixedHeader columns={columns} data={TradingCommission} />
                )}
                <div className="container pt-3 pb-4  table_control" >
                  <div className=" row align-items-center justify-content-start" >
                    <div className="col-md-4" >
                      <div className=" justify-content-start " role="group">
                        <div className="d-flex justify-content-start gap-2">
                          <button className=" btn btn-primary btn-sm" type="button" disabled={skipCount <= 1 } onClick={() => { tradingCommission(skipCount - 10) }} >
                            Previous
                          </button>
                          <button className=" btn btn-primary btn-sm" type="button" disabled={((skipCount + 10 ) === totalCount) || TradingCommission?.length === 0} onClick={() => { tradingCommission(skipCount + 10) }} >
                            Next
                          </button>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>


              </div>

            </>
          </div>
        </div>
      </main>
    </div>
  );
};

export default TradingCommision;
