import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Loginpage from "../ui/pages/LoginPage";
import ForgetpasswordPage from "../ui/pages/ForgetpasswordPage";
import DashboardPage from "../ui/pages/DashboardPage";
import { ToastContainer } from "react-toastify";
import HomePage from "../ui/pages/HomePage";
import AddsubAdmin from "../ui/pages/AddsubAdmin";
import SubadminList from "../ui/pages/SubadminList";
import TradeList from "../ui/pages/TradeList";
import AddTrade from "../ui/pages/AddTrade";
import PendingKyc from "../ui/pages/PendingKyc";
import ApprovedKyc from "../ui/pages/ApprovedKyc";
import CurrencyManagement from "../ui/pages/CurrencyManagement";
import CurrencypairManagement from "../ui/pages/CurrencypairManagement";
import FundsManagement from "../ui/pages/FundsManagement";
import FundsDManagement from "../ui/pages/FundsDManagement";
import Notification from "../ui/pages/Notification";
import ContentManager from "../ui/pages/ContentManager";
import BannerManagement from "../ui/pages/BannerManagement";
import RejectedKyc from "../ui/pages/RejectedKyc";
import TradingCommision from "../ui/pages/TradingCommision";
import OrderBook from "../ui/pages/OrderBook";
import TradingReport from "../ui/pages/TradingReport";
import UserPendingBank from "../ui/pages/UserPendingBank";
import UserApprovedBank from "../ui/pages/UserApprovedBank";
import UserRejectedBank from "../ui/pages/UserRejectedBank";
import ExchangeWalletManagement from "../ui/pages/ExchangeWalletManagement";
import FiatManagement from "../ui/pages/FiatManagement";
import FiatDManagement from "../ui/pages/FiatDManagement";
import TraderDetails from "../ui/pages/TraderDetails";
import TodayRegestration from "../ui/pages/TodayRegestration";
import VerifyKyc from "../ui/pages/VerifyKyc";
import CurrencyList from "../ui/pages/P2P/CurrencyList";
import PaymentOptions from "../ui/pages/P2P/PaymentOptions";
import SupportPage from "../ui/pages/SupportPage";
import History from "../ui/pages/P2P/History";
import UserApprovedUPI from "../ui/pages/UserApprovedUPI";
import UserPendingUPI from "../ui/pages/UserPendingUPI";
import UserRejectedUPI from "../ui/pages/UserRejectedUPI";

const Routing = () => {
    const token = sessionStorage.getItem('token');

    return (
        <Router>
            {/*  // ********* Component for AlertBox ********** // */}
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss={false} draggable pauseOnHover limit={1} theme="light" />

            <Routes>
                {token ?
                    <>
                        <Route exact path="/dashboard" element={<DashboardPage />} >
                            <Route index path="*" element={<HomePage />}></Route>
                            <Route exect path="homepage" element={<HomePage />}></Route>
                            <Route exect path="listsubadmin" element={<SubadminList />}></Route>
                            <Route exect path="addsubadmin" element={<AddsubAdmin />}></Route>
                            <Route exect path="tradelist" element={<TradeList />}></Route>
                            <Route exect path="TraderDetails" element={<TraderDetails />}></Route>
                            <Route exect path="addnewtrade" element={<AddTrade />}></Route>
                            <Route exect path="pendingkyc" element={<PendingKyc />}></Route>
                            <Route exect path="VerifyKyc" element={<VerifyKyc />}></Route>
                            <Route exect path="approvedkyc" element={<ApprovedKyc />}></Route>
                            <Route exect path="currencymanagement" element={<CurrencyManagement />}></Route>
                            <Route exect path="currencypair" element={<CurrencypairManagement />}></Route>
                            <Route exect path="tradingfeereport" element={<TradingReport />}></Route>
                            <Route exect path="fundsManagement" element={<FundsManagement />}></Route>
                            <Route exect path="fundsDManagement" element={<FundsDManagement />}></Route>
                            <Route exect path="notification" element={<Notification />}></Route>
                            <Route exect path="content" element={<ContentManager />}></Route>
                            <Route exect path="bannerManagement" element={<BannerManagement />}></Route>
                            <Route exect path="RejectedKyc" element={<RejectedKyc />}></Route>
                            <Route exect path="TradingCommision" element={<TradingCommision />}></Route>
                            <Route exect path="OrderBook" element={<OrderBook />}></Route>
                            <Route exect path="UserPendingBank" element={<UserPendingBank />}></Route>
                            <Route exect path="UserApprovedBank" element={<UserApprovedBank />}></Route>
                            <Route exect path="UserRejectedBank" element={<UserRejectedBank />}></Route>
                            <Route exect path="UserPendingUPI" element={<UserPendingUPI />}></Route>
                            <Route exect path="UserApprovedUPI" element={<UserApprovedUPI />}></Route>
                            <Route exect path="UserRejectedUPI" element={<UserRejectedUPI />}></Route>
                            <Route exect path="ExchangeWalletManagement" element={<ExchangeWalletManagement />}></Route>
                            <Route exect path="fiatManagement" element={<FiatManagement />}></Route>
                            <Route exect path="fiatDManagement" element={<FiatDManagement />}></Route>
                            <Route exect path="TodayRegestration" element={<TodayRegestration />}></Route>
                            <Route exect path="p2pCoinList" element={<CurrencyList />}></Route>
                            <Route exect path="p2pPaymentOptions" element={<PaymentOptions />}></Route>
                            <Route exect path="p2pHistory" element={<History />}></Route>
                            <Route exect path="support" element={<SupportPage />}></Route>
                        </Route>
                        <Route exect path="/*" element={<DashboardPage />}></Route>
                    </>
                    :
                    <>
                        <Route exect path="/" element={<Loginpage />}></Route>
                        <Route exect path="/*" element={<Loginpage />}></Route>
                        <Route exect path="/forgotpassword" element={<ForgetpasswordPage />}></Route>
                    </>

                }


            </Routes>
        </Router>
    );
}

export default Routing;