import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponent/CustomAlertMessage";
import { CSVLink } from "react-csv";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import moment from "moment";
import { $ } from "react-jquery-plugin";
import DataTable from "react-data-table-component";

const OrderBook = () => {

    const [orderBookDetails, setorderBookDetails] = useState([]);
    const [tradeDetails, settradeDetails] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [skipCount, setSkipCount] = useState(0);

    const serialNo = (skipCount) => (row, rowIndex) => {
        return (
            <>
                {rowIndex + 1 + skipCount}
            </>
        );
    };

    const dateFormatter = (row) => {
        return <>{moment(row?.updatedAt).format("DD/MM/YYYY")}</>;
    };

    const Modal = (row) => {
        return <span className="cursor_pointer" onClick={() => { $("#Trades_modal").modal("show"); tradeById(row?._id) }}> {row?._id}</span>
    };
    const tradeById = async (id) => {
        settradeDetails([])
        LoaderHelper.loaderStatus(true);
        await AuthService.tradeById(id).then(
            async (result) => {
                if (result.success) {
                    settradeDetails(result?.data)
                    LoaderHelper.loaderStatus(false);

                } else {
                    LoaderHelper.loaderStatus(false);
                    alertErrorMessage(result.message);
                }
            }
        );
    };

    const CancelOrder = (row) => {
        return (
            <>{(row?.status !== "FILLED" && row?.status !== "CANCELLED") &&
                <div className="d-flex">
                    <button className=" btn btn-danger btn-sm" type="button" onClick={() => HandleCancelOrder(row?._id, row?.user_id)} >
                        Cancel
                    </button>
                </div>
            }
            </>
        );
    };
    const HandleCancelOrder = async (orderID, userID) => {
        LoaderHelper.loaderStatus(true)
        await AuthService.cancelOrder(orderID, userID).then(async result => {
            LoaderHelper.loaderStatus(false)
            if (result?.success) {
                HandleOrderBook();
                alertSuccessMessage(result?.message)

            } else {
                alertErrorMessage(result?.message)
            }
        })
    }
    const columns = [

        { name: 'Serial no.', selector: serialNo(skipCount), },
        { name: 'User Id', wrap: true, selector: row => row.user_id, },
        { name: 'Order Id', wrap: true, selector: Modal, },
        { name: 'Date/Time', selector: dateFormatter, },
        { name: 'Currency', selector: row => row.main_currency, },
        { name: 'Order Type', selector: row => row.order_type, },
        { name: 'Filled', sortable: true, selector: row => row?.filled && parseFloat(row?.filled?.toFixed(8)) },
        { name: 'Maker Fee', sortable: true, selector: row => row.maker_fee, },
        { name: 'Taker Fee', sortable: true, selector: row => row.taker_fee, },
        { name: 'Price', sortable: true, selector: row => row.price, },
        { name: 'Remaining', sortable: true, selector: row => row.remaining, },
        { name: 'TDS', selector: row => row.tds, },
        { name: 'Status', selector: row => row.status, },
        { name: 'Side', selector: row => row.side, },
        { name: 'Action', selector: CancelOrder },
    ]


    useEffect(() => {
        HandleOrderBook(0)
    }, []);

    function roundUpToNearestMultipleOf10(number) {
        const remainder = number % 10;
        const differenceToNextMultiple = 10 - remainder;
        const roundedNumber = remainder === 0 ? number : number + differenceToNextMultiple;

        return roundedNumber;
    }

    const HandleOrderBook = async (skip) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.OrderBook(skip, 10).then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setorderBookDetails(result?.data?.reverse());
                    setSkipCount(skip);
                    let count = roundUpToNearestMultipleOf10(result?.data?.count)
                    setTotalCount(count)


                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
            }
        });
    }

    return (
        <>
            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i className="far fa-user"></i></div>
                                            OrderBook
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="container-xl px-4 mt-n10">
                        <div className="card mb-4">
                            <div class="card-header">
                                OrderBook Details
                                {orderBookDetails.length === 0 ? "" :
                                    <div class="dropdown">
                                        <button class="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Export{" "}
                                        </button>
                                        <div class="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                            <CSVLink data={orderBookDetails} class="dropdown-item">Export as CSV</CSVLink>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div class="table-responsive">
                                {orderBookDetails.length === 0 ? (
                                    <h6 className="ifnoData">
                                        <img alt="" src="/assets/img/no-data.png" /> <br /> No Data
                                        Available
                                    </h6>
                                ) : (
                                    <DataTable direction="auto" responsive subHeaderAlign="right" subHeaderWrap striped highlightOnHover fixedHeader columns={columns} data={orderBookDetails} />
                                )}

                                <div className="container pt-3 pb-4  table_control" >
                                    <div className=" row align-items-center justify-content-start" >
                                        <div className="col-md-4" >
                                            <div className=" justify-content-start " role="group">
                                                <div className="d-flex justify-content-start gap-2">
                                                    <button className=" btn btn-primary btn-sm" type="button" disabled={skipCount <= 1} onClick={() => { HandleOrderBook(skipCount - 10) }} >
                                                        Previous
                                                    </button>
                                                    <button className=" btn btn-primary btn-sm" type="button" disabled={((skipCount + 10) === totalCount) || orderBookDetails?.length === 0} onClick={() => { HandleOrderBook(skipCount + 10) }} >
                                                        Next
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </main>
            </div>
            <div class="modal" id="Trades_modal" tabindex="-1" role="dialog" aria-labelledby="Trades_modal_modalTitle" aria-hidden="true">
                <div class="modal-dialog  alert_modal" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalCenterTitle">
                                Trade Details
                            </h5>
                            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <div className="modal-body">
                            {tradeDetails.length > 0 ? tradeDetails?.map((item, index) => {
                                return (
                                    <>
                                        <div className="form-group position-relative ">
                                            <label class="small">Currency: {item?.currency} </label>
                                        </div>
                                        <div className="form-group   position-relative ">
                                            <label class="small ">Price: {item?.price} </label>
                                        </div>
                                        <div className="form-group position-relative ">
                                            <label class="small mb-1">Quantity: {item?.quantity} </label>
                                        </div>
                                        <hr />
                                    </>
                                )
                            }) : 'No Data Found'}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrderBook;