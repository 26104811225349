import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage, } from "../../../customComponent/CustomAlertMessage";
import TraderDetails from "../TraderDetails";
import moment from "moment";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import Papa from 'papaparse';
import 'jspdf-autotable';
import DataTableBase from "../../../customComponent/DataTable";


const TradeList = (props) => {
  const [activeScreen, setActiveScreen] = useState("userdetail");
  const [userId, setUserId] = useState("");
  const [exportData, setExportData] = useState([]);
  const [kycStatus, setkycStatus] = useState();
  const [tds, settds] = useState('');
  const [traderData, settraderData] = useState();
  const linkFollow = (row) => {
    return (
      <div>
        <button className="btn btn-dark btn-sm   me-2"
          onClick={() => { settraderData(row); setUserId(row?._id); setActiveScreen("detail"); setkycStatus(row?.kyc_verified);settds(row?.tds) }}>
          View
        </button>
      </div>
    );
  };

  const nameFormatter = (row) => {
    return (
      row?.firstName + ' ' + row?.lastName
    );
  };

  const dateFilter = (row) => {
    return <>{moment(row?.createdAt).format("Do MMMM YYYY")}</>;
  };

  const columns = [
    { name: "User ID", selector: row => row._id, },
    { name: "Name", sort: true,wrap:true, selector: nameFormatter, },
    { name: "Email", sort: true,wrap:true, selector: row => row.emailId, },
    { name: "Phone Number", sort: true, selector: row => row.mobileNumber, },
    { name: "Registration Date", sort: true, selector: dateFilter },
    { name: "Action", selector: linkFollow, },

  ];

  useEffect(() => {
    handleExportData();
  }, []);

  const handleStatus = async (_id, status) => {
    await AuthService.updateStatus(_id, status).then(
      async (result) => {
        if (result.success) {
          try {
            handleExportData();
            alertSuccessMessage(result.message);
          } catch (error) {
            alertErrorMessage(error);
          }
        } else {
          alertErrorMessage(result.message);
        }
      }
    );
  };

  const handleExportData = async (dateFrom, dateTo) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getExportList(dateFrom, dateTo).then(async (result) => {
      if (result.data.length > 0) {
        LoaderHelper.loaderStatus(false);
        try {
          setExportData(result?.data.reverse());
        } catch (error) {
          alertErrorMessage(error);
          console.log("error", `${error}`);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage("No Data Available");
      }
    });
  };

  // *********Export Trade Data In Excel Formats ************* // 
  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Trades');
    XLSX.writeFile(wb, ' Traders List.xlsx');
  };


  // *********Export Trade Data In PDF Formats ************* // 
  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.text(' Traders List', 10, 10);
    const keys = Object.keys(exportData[0]);
    const tableHead = keys.map(key => key.charAt(0).toUpperCase() + key.slice(1));
    const tableData = exportData.map(trade => keys.map(key => trade[key]));

    doc.autoTable({
      head: [tableHead],
      body: tableData,
      styles: {
        fontSize: 7,
      },
    });
    doc.save(' Traders List.pdf');
  };


  // *********Export Trade Data In CSV Formats ************* // 
  const exportToCSV = () => {
    const csv = Papa.unparse(exportData);
    const blob = new Blob([csv], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = ' Traders List.csv';
    link.click();
  };
  const HandleExport = (exportData) => {
    if (exportData === 'EXCEL') {
      exportToExcel()
    }
    else if (exportData === 'PDF') {
      exportToPDF()
    }
    else if (exportData === 'CSV') {
      exportToCSV()
    }
  };

  return activeScreen === "userdetail" ? (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
          <div className="container-xl px-4">
            <div className="page-header-content pt-4">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto mt-4">
                  <h1 className="page-header-title">
                    <div className="page-header-icon">
                      <i className="fa fa-wave-square"></i>
                    </div>
                    Traders List
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-xl px-4 mt-n10">
          <div className="card mb-4">
            <div className="card-header">
              Traders Details
              <div className="dropdown">
                <button
                  className="btn btn-dark btn-sm dropdown-toggle"
                  id="dropdownFadeInUp"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {" "}
                  <i className="fa fa-download me-3"></i> Export
                </button>
                <div class="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                  <button type="button" onClick={() => { HandleExport('EXCEL') }} class="dropdown-item">Export as EXCEL</button>
                  <button type="button" onClick={() => { HandleExport('CSV') }} class="dropdown-item">Export as CSV</button>
                  <button type="button" onClick={() => { HandleExport('PDF') }} class="dropdown-item">Export as PDF</button>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <DataTableBase columns={columns} data={exportData} />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  ) : (
    <TraderDetails userId={userId} kycStatus={kycStatus} TDS={tds} traderData={traderData} />
  );
};

export default TradeList;
