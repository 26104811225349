import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage } from "../../../customComponent/CustomAlertMessage";
import { CSVLink } from "react-csv";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import DataTableBase from "../../../customComponent/DataTable";

const TradingReport = () => {
    const [tradingReport, setTradingReport] = useState([]);

    const linkFollow = (row) => {
        return (row?.amount)?.toFixed(2)
    };

    const linkFollowTds = (row) => {
        return (row?.tdsAmount)?.toFixed(7)
    };

    const columns = [
        { name: 'Order ID', selector: row => row.orderId, },
        { name: 'Coin Name', selector: row => row.coin, },
        { name: 'Email', selector: row => row.email, },
        { name: 'Tds Amount', sort: true, selector: linkFollowTds },
        { name: 'Order Type', selector: row => row.orderType, },
        { name: 'Trading Amount (In INR)', sort: true, selector: linkFollow },
    ];


    useEffect(() => {
        // handleTradingReport()
    }, []);

    const handleTradingReport = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getTradingReport().then(async result => {
            if (result.data.length > 0) {
                LoaderHelper.loaderStatus(false);
                try {
                    setTradingReport(result?.data.reverse());
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
            }
        });
    }

    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"><i className="far fa-user"></i></div>
                                        Trading Fee Report
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4 mt-n10">
                    <div className="card mb-4">
                        <div class="card-header">
                            Trading Fee Report List
                            {tradingReport.length === 0 ? "" :
                                <div class="dropdown">
                                    <button class="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Export{" "}
                                    </button>
                                    <div class="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                        <CSVLink data={tradingReport} class="dropdown-item">Export as CSV</CSVLink>
                                    </div>
                                </div>
                            }
                        </div>
                        <>
                            {
                                tradingReport.length === 0 ? <h6 className="ifnoData"><img alt="" src="/assets/img/no-data.png" />
                                    <br />No Data Available</h6> :
                                    <div className="card-body">
                                        <table className="" width="100%" >
                                            <DataTableBase columns={columns} data={tradingReport} />
                                        </table>
                                    </div>
                            }
                        </>

                    </div>
                </div>
            </main>
        </div>
    )
}

export default TradingReport;