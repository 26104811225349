import React, { useState } from "react";
import { CSVLink } from "react-csv";
import DataTableBase from "../../../customComponent/DataTable";
import AuthService from "../../../api/services/AuthService";
import { useEffect } from "react";
import { ApiConfig } from "../../../api/apiConfig/ApiConfig";
import moment from "moment";
import { Link } from "react-router-dom";
import HomePage from "../HomePage";

const TodayRegestration = () => {
    const [todayRegisList, settodayRegisList] = useState([]);
    const [activeScreen, setActiveScreen] = useState('TodayRegestration');

    useEffect(() => {
        newRegistrations()
    }, []);

    const newRegistrations = async () => {
        await AuthService.getNewRegistration().then(async result => {
            if (result?.success) {
                try {
                    settodayRegisList(result?.data?.data)
                } catch (error) {
                }
            }
        })
    };

    const userIdFormatter = (row) => {
        return row?.id;
    };
    const imageFormatter = (row) => {
        return (
            <img
                style={{ width: '40%', height: 'auto' }}
                className="table-img"
                src={ApiConfig?.uploadUrl + row?.profilepicture}
                alt="Selfie"
            />
        );
    };

    const dateFilter = (row) => {
        return <>{moment(row?.createdAt).format("Do MMMM YYYY")}</>;
    };

    const columns = [
        { name: "ID", selector: userIdFormatter, },
        { name: "FirstName", selector: row => row.firstName, },
        { name: "LastName", selector: row => row.lastName, },
        { name: "Mobile Number", selector: row => row.mobileNumber, },
        { name: "Selfie", selector: imageFormatter, },
        { name: "Date", selector: dateFilter, },
    ];


    return (
        activeScreen === 'TodayRegestration' ?
            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i className="fa fa-wave-square" ></i></div>
                                            <div className="col-auto mt-4">
                                                <h1 className="page-header-title">
                                                    Today's Registrations List
                                                </h1>
                                            </div>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="container-xl px-4 mt-n10">
                        <div className="filter_bar" >
                        </div>
                        <div className="card mb-4">
                            <div class="card-header">Today's Registrations Details
                                {!todayRegisList ? "" :
                                    <div class="dropdown">
                                        <button class="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Export</button>
                                        <div class="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                            <CSVLink data={todayRegisList} class="dropdown-item">Export as CSV</CSVLink>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="card-body">
                                <div class="table-responsive">
                                    {!todayRegisList ? <h6 className="ifnoData"><img alt="" src="/assets/img/no-data.png" /> <br /> No Registration Today</h6> :
                                        <DataTableBase columns={columns} data={todayRegisList} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            : <HomePage />
    )
}

export default TodayRegestration;