
import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import moment from "moment";
import { alertErrorMessage, } from "../../../customComponent/CustomAlertMessage";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import Papa from 'papaparse';
import 'jspdf-autotable';
import DataTableBase from "../../../customComponent/DataTable";

const UserPendingBank = () => {
    const [data, setData] = useState([]);
    const [dateFrom, setDateFrom] = useState("");
    const [dateTo, setDateTo] = useState("");


    const status = (row) => {
        return (
            <div className=" d-flex gap-2 " >
                {row?.verified === 0 ? <>
                    <button className="btn-success  btn btn-sm" type="button" onClick={() => VerifyBankDetails(row?._id, '1')}>Approve
                    </button>
                    <button className="btn-danger  btn btn-sm" type="button" onClick={() => VerifyBankDetails(row?._id, '2')}>Reject
                    </button></> : row?.verified === 1 ? <span className="col-3 text-success" >Approved </span> : <span className="col-3 text-danger" >Rejected </span>}
            </div>
        );
    };


    const dateFilter = (row) => {
        return <>{moment(row?.createdAt).format("Do MMMM YYYY")}</>;
    };

    const columns = [

        { name: "Name", selector: row => row.account_holder_name, },
        { name: "Account Number", sort: true, selector: row => row.account_number, },
        { name: "Bank Name", sort: true, selector: row => row.bank_name, },
        { name: "Branch Name", sort: true, selector: row => row.branch_name, },
        { name: "IFSC Code", sort: true, selector: row => row.ifsc_code, },
        { name: "Date", sort: true, selector: dateFilter },
        { name: "Status", sort: true, selector: status },
    ];
    useEffect(() => {
        handleData();
    }, []);

    const handleData = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.pendingBankDetails().then(async (result) => {
            if (result.data.length > 0) {
                LoaderHelper.loaderStatus(false);
                try {
                    setData(result.data.reverse());
                    setDateFrom("");
                    setDateTo("");
                } catch (error) {
                    alertErrorMessage(error);
                    console.log("error", `${error}`);
                }
            } else {
                LoaderHelper.loaderStatus(false);
            }
        });
    };
    const VerifyBankDetails = async (id, status) => {
        await AuthService.verifyBankDetails(id, status).then(async result => {
            if (result?.success) {
                try {
                    handleData();
                    window.location.reload()
                } catch (error) {
                    console.log('error', `${error}`);
                }
            } else {
                alertErrorMessage(result.message)
            }
        });
    }
    const handleInputChange = (event) => {
        switch (event.target.name) {
            case "dateFrom":
                setDateFrom(event.target.value);
                break;
            case "dateTo":
                setDateTo(event.target.value);
                break;
            default:
        }
    };

    const handleExportData = async (dateFrom, dateTo) => {
        await AuthService.exportPandingList(dateFrom, dateTo).then(
            async (result) => {
                if (result.data.length > 0) {
                    try {
                        setData(result.data.reverse());
                    } catch (error) {
                        alertErrorMessage(error);
                        console.log("error", `${error}`);
                    }
                } else {
                    alertErrorMessage("No Data Found");
                    console.log(result, "query");
                }
            }
        );
    };
    // *********Export Trade Data In Excel Formats ************* // 
    const exportToExcel = () => {
        const exportableData = ExportableData();
        const ws = XLSX.utils.json_to_sheet(exportableData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Trades');
        XLSX.writeFile(wb, 'Pending Bank Accounts.xlsx');
    };


    // *********Export Trade Data In PDF Formats ************* // 
    const exportToPDF = () => {
        const exportableData = ExportableData();
        const doc = new jsPDF('landscape', 'mm', 'a4');
        doc.text('Pending Bank Accounts', 10, 10);
        const tableData = exportableData.map(item => Object.values(item));
        const tableHeaders = Object.keys(exportableData[0]); // Get keys from the first object
        doc.autoTable({
            head: [tableHeaders],
            body: tableData,
            startY: 20,
            theme: 'grid',
        });
        doc.save('Pending Bank Accounts.pdf');
    };

    // ********* Rearrange Exportabel Data ********* //
    const ExportableData = () => {
        const modifiedArray = data.map((item, index) => {
            const { updatedAt, user_id, __v, _id, kyc_reject_reason, kycdata, createdAt, ...rest } = item;
            const modifiedItem = {};
            for (const key in rest) {
                if (Object.hasOwnProperty.call(rest, key)) {
                    const modifiedKey = key.toUpperCase();
                    modifiedItem[modifiedKey] = rest[key];
                }
            }
            modifiedItem.CREATED_AT = moment(createdAt).format('DD/MM/YYYY hh:mm A');
            return modifiedItem;
        });
        return modifiedArray;

    }
    // *********Export Trade Data In CSV Formats ************* // 
    const exportToCSV = () => {
        const exportableData = ExportableData();
        const csv = Papa.unparse(exportableData);
        const blob = new Blob([csv], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'Pending Bank Accounts.csv';
        link.click();
    };
    const HandleExport = (exportData) => {
        if (exportData === 'EXCEL') {
            exportToExcel()
        }
        else if (exportData === 'PDF') {
            exportToPDF()
        }
        else if (exportData === 'CSV') {
            exportToCSV()
        }
    };

    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon">
                                            <i className=" fa fa-user-slash"></i>
                                        </div>
                                        Pending Bank Accounts
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4 mt-n10">
                    {data.length === 0 ? (
                        ""
                    ) : (
                        <div className="filter_bar">
                            <form className="row">
                                <div class="mb-3 col ">
                                    <input
                                        type="date"
                                        class="form-control form-control-solid"
                                        data-provide="datepicker"
                                        id="litepickerRangePlugin"
                                        name="dateFrom"
                                        value={dateFrom}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div class="mb-3 col ">
                                    <input
                                        type="date"
                                        class="form-control form-control-solid"
                                        data-provide="datepicker"
                                        id="litepickerRangePlugin"
                                        name="dateTo"
                                        value={dateTo}
                                        onChange={handleInputChange}
                                    />
                                </div>
                                <div class="mb-3 col ">
                                    <div className="row">
                                        <div className="col">
                                            <button
                                                class="btn btn-indigo btn-block w-100"
                                                type="button"
                                                onClick={() => handleExportData(dateFrom, dateTo)}
                                                disabled={!dateFrom || !dateTo}
                                            >
                                                Search
                                            </button>
                                        </div>
                                        <div className="col">
                                            <button
                                                className="btn btn-indigo btn-block w-100"
                                                type="button"
                                                onClick={handleData}
                                            >
                                                Reset
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    )}
                    <div className="card mb-4">
                        <div class="card-header">
                            Pending Bank List
                            {data.length === 0 ? (
                                ""
                            ) : (
                                <div class="dropdown">
                                    <button
                                        class="btn btn-dark btn-sm dropdown-toggle"
                                        id="dropdownFadeInUp"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <i className="fa fa-download me-3"></i>Export
                                    </button>
                                    <div class="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                        <button type="button" onClick={() => { HandleExport('EXCEL') }} class="dropdown-item">Export as EXCEL</button>
                                        <button type="button" onClick={() => { HandleExport('CSV') }} class="dropdown-item">Export as CSV</button>
                                        <button type="button" onClick={() => { HandleExport('PDF') }} class="dropdown-item">Export as PDF</button>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="card-body">
                            <>
                                {data.length === 0 ? (
                                    <h6 className="ifnoData">
                                        <img src="/assets/img/no-data.png" /> <br /> No Data
                                        Available
                                    </h6>
                                ) : (
                                    <div class="table-responsive">
                                        <DataTableBase columns={columns} data={data} />
                                    </div>
                                )}
                            </>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
};
export default UserPendingBank;
