import React, { useState, useEffect } from "react";
import { alertErrorMessage, alertSuccessMessage, } from "../../../customComponent/CustomAlertMessage";
import AuthService from "../../../api/services/AuthService";
import { $ } from "react-jquery-plugin";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import Papa from 'papaparse';
import 'jspdf-autotable';
import moment from "moment";
import DataTableBase from "../../../customComponent/DataTable";

const ExchangeWalletManagement = () => {
  const [userWalletList, setUserWalletList] = useState([]);
  const [coinName, setCoinName] = useState("USDT");
  const [coinList, setCoinList] = useState([]);
  const [coinId, setCoinId] = useState([]);
  const [type, setType] = useState("");
  const [amount, setAmount] = useState("");
  const [userId, setUserId] = useState("");


  useEffect(() => {
    handleUserWalletList("USDT");
    handleCoinList();
  }, []);


  const handleUserWalletList = async (coinName) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getUserWalletList(coinName).then(async (result) => {

      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setUserWalletList(result.data);
          setCoinId(result.data[0]?.currency_id)
        } catch (error) {
          alertErrorMessage(error);

        }
      } else {
        LoaderHelper.loaderStatus(false);
      }
    });
  };

  const handleCoinList = async () => {
    await AuthService.coinlist().then(async (result) => {

      if (result.success) {
        try {
          setCoinList(result.data);
        } catch (error) {
          alertErrorMessage(error);

        }
      } else {
        alertErrorMessage(result.message);
      }
    });
  };


  const linkFollow = (row) => {
    return (
      <div>
        <button
          type="button"
          className="btn btn-sm btn-primary"
          onClick={() => { setUserId(row?.userId); showTransfer(); }}        >
          Debit/Credit
        </button>
      </div>
    );
  };

  const balanceFormatter = (row) => {
    return (
      <div>
        {row?.balance.toFixed(5)}
      </div>
    );
  };

  const lockedBalanceFormatter = (row) => {
    return (
      <div>
        {row?.locked_balance.toFixed(5)}
      </div>
    );
  };

  const handleUserWalletTransfer = async (coinId, userId, amount, type) => {
    LoaderHelper.loaderStatus(true);
    await AuthService.fundsTransfer(coinId, userId, amount, type).then(
      async (result) => {
        // 

        if (result.success) {
          LoaderHelper.loaderStatus(false);
          handleUserWalletList(coinName)
          $("#funds_modal").modal("hide");
          alertSuccessMessage(result.message);
          setAmount("");
        } else {
          LoaderHelper.loaderStatus(false);
          alertErrorMessage(result.message);
        }
      }
    );
  };

  const showTransfer = () => {
    $("#funds_modal").modal("show");
  };

  const columns = [
    { name: "User Id", selector: row => row.userId, },
    { name: "Email Id", selector: row => row.emailId, },
    { name: "Coin Name", selector: row => row.short_name, },
    { name: "Available", selector: balanceFormatter },
    { name: "Inorder", selector: lockedBalanceFormatter },
    { name: "Action", selector: linkFollow },
  ];


  // *********Export Trade Data In Excel Formats ************* // 
  const exportToExcel = () => {
    const exportableData = ExportableData();
    const ws = XLSX.utils.json_to_sheet(exportableData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Trades');
    XLSX.writeFile(wb, 'Exchange Wallet Details.xlsx');
  };


  // *********Export Trade Data In PDF Formats ************* // 
  const exportToPDF = () => {
    const exportableData = ExportableData();
    const doc = new jsPDF('landscape', 'mm', 'a4');
    doc.text('Exchange Wallet Details', 10, 10);
    const tableData = exportableData.map(item => Object.values(item));
    const tableHeaders = Object.keys(exportableData[0]); // Get keys from the first object
    doc.autoTable({
      head: [tableHeaders],
      body: tableData,
      startY: 20,
      theme: 'grid',
    });
    doc.save('Exchange Wallet Details.pdf');
  };

  // *********Export Trade Data In CSV Formats ************* // 
  const exportToCSV = () => {
    const exportableData = ExportableData();
    const csv = Papa.unparse(exportableData);
    const blob = new Blob([csv], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'Exchange Wallet Details.csv';
    link.click();
  };

  // ********* Rearrange Exportabel Data ********* //
  const ExportableData = () => {
    const modifiedArray = userWalletList.map((item, index) => {
      const { updatedAt, user_id, __v, _id, createdAt, ...rest } = item;
      const modifiedItem = {};
      for (const key in rest) {
        if (Object.hasOwnProperty.call(rest, key)) {
          const modifiedKey = key.toUpperCase();
          modifiedItem[modifiedKey] = rest[key];
        }
      }
      modifiedItem.CREATED_AT = moment(createdAt).format('DD/MM/YYYY hh:mm A');
      return modifiedItem;
    });
    return modifiedArray;

  }

  const HandleExport = (exportData) => {
    if (exportData === 'EXCEL') {
      exportToExcel()
    }
    else if (exportData === 'PDF') {
      exportToPDF()
    }
    else if (exportData === 'CSV') {
      exportToCSV()
    }
  };

  return (
    <>
      <div id="layoutSidenav_content">
        <main>
          <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
            <div className="container-xl px-4">
              <div className="page-header-content pt-4">
                <div className="row align-items-center justify-content-between">
                  <div className="col-auto mt-4">
                    <h1 className="page-header-title">
                      <div className="page-header-icon">
                        <i className="fa fa-wallet"></i>
                      </div>
                      Exchange Wallet Management
                    </h1>
                  </div>
                  <div class="col-auto mt-4">
                    <select
                      class="form-control form-control-solid form-select form-select-dark"
                      id="exampleFormControlSelect1"
                      value={coinName}
                      onChange={(e) => setCoinName(e.target.value)}
                    >
                      <option value="" selected="selected" hidden="hidden">
                        Choose here
                      </option>
                      {coinList.length > 0
                        ? coinList.map((item, index) => (
                          <option>{item?.short_name}</option>
                        ))
                        : undefined}
                    </select>
                    <button
                      class="btn btn-success btn-block w-100 mt-3"
                      type="button"
                      onClick={() => handleUserWalletList(coinName)}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </header>
          <div className="container-xl px-4 mt-n10">
            <div className="row">
              <div className="col-xl-12">
                <div class="card">
                  <div class="card-header">
                    Exchange Wallet Details
                    <div class="dropdown">
                      <button
                        class="btn btn-dark btn-sm dropdown-toggle"
                        id="dropdownFadeInUp"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Export
                      </button>
                      <div class="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                        <button type="button" onClick={() => { HandleExport('EXCEL') }} class="dropdown-item">Export as EXCEL</button>
                        <button type="button" onClick={() => { HandleExport('CSV') }} class="dropdown-item">Export as CSV</button>
                        <button type="button" onClick={() => { HandleExport('PDF') }} class="dropdown-item">Export as PDF</button>
                      </div>
                    </div>
                  </div>

                  {userWalletList.length === 0 ? <h6 className="ifnoData"><img alt="" src="/assets/img/no-data.png" />
                    <br />No Data Available</h6> :
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12">
                          <div class="table-responsive">

                            <DataTableBase columns={columns} data={userWalletList} />
                          </div>
                        </div>
                      </div>
                    </div>}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      {/* funds modal */}
      <div
        class="modal"
        id="funds_modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="funds_modal_modalTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog  alert_modal" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalCenterTitle">
                Debit/Credit
              </h5>
              <button
                class="btn-close"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group  mb-3 position-relative ">
                  <label class="small mb-1">Select Type</label>
                  <select
                    class="form-control  form-control-solid input-copy"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option>Select</option>
                    <option value="CREDIT">CREDIT</option>
                    <option value="DEBIT">DEBIT</option>
                  </select>
                </div>
                {/*  */}

                {/*  */}
                <div className="form-group  mb-3 position-relative ">
                  <label class="small mb-1"> Amount </label>
                  <input
                    class="form-control  form-control-solid input-copy"
                    type="text"
                    Placeholder="Enter Amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  ></input>
                </div>
                <div className="form-group  mb-3 position-relative">
                  <button
                    class="btn btn-primary   btn-block w-100"
                    type="button"
                    onClick={() =>
                      handleUserWalletTransfer(coinId, userId, amount, type)
                    }
                  >
                    Debit/Credit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* transfer_modal modal data */}
    </>
  );
};
export default ExchangeWalletManagement;