import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage } from "../../../customComponent/CustomAlertMessage";
import moment from "moment";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import Papa from 'papaparse';
import 'jspdf-autotable';
import DataTableBase from "../../../customComponent/DataTable";

const FiatManagement = () => {
    const [fiatWithdraw, setFiatWithdraw] = useState([]);
    const [bankDetails, setBankDetails] = useState({});
    
    const dateFilter = (row) => {
        return (
            moment(row?.createdAt).format('Do MMMM YYYY, h:mm: a')
        );
    };

    const linkFollow = (row) => {
        return (
            row?.status === 'PENDING' ?
                <>
                    <button className="btn btn-success btn-sm me-2" onClick={() => handleWithdStatus(row?._id, 'COMPLETE')}>Approve</button>
                    <button className="btn btn-danger btn-sm mt-2 " onClick={() => handleWithdStatus(row?._id, 'CANCELLED')}>Denied</button>

                </> : <span className={`badge text-white rounded-pill ${row?.status === 'COMPLETE' ? "bg-success" : "bg-danger"} `}>{row?.status}</span>
        );
    };

    const BankDetails = (row) => {
        return (
            <button className="btn btn-outline-success btn-sm me-2" data-bs-toggle="modal" data-bs-target="#bank_modal" type="button" onClick={() => setBankDetails(row?.user_bank ? row?.user_bank : {})}> Bank</button>

        );
    };

    const columns = [
        { name: 'Date & Time', sortable: true, selector: dateFilter },
        { name: 'Name', selector: row => row?.firstName + ' ' + row.lastName },
        { name: 'Email', selector: row => row?.emailId },
        { name: 'Amount', sortable: true, selector: row => row?.amount },
        { name: 'Fee', sortable: true, selector: row => row?.fee },
        { name: 'Bank Details', selector: BankDetails },
        { name: "Status", selector: linkFollow },
    ];

    const handleWithdStatus = async (id, status) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.updateWithdrawalStatus(id, status).then(async result => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                handleFiatWithdraw();
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message)
            }
        })
    };

    useEffect(() => {
        handleFiatWithdraw()
    }, []);

    const handleFiatWithdraw = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getFiatWithdraw().then(async result => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setFiatWithdraw(result?.data?.reverse());
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage("No data found");
            }
        });
    }

    // *********Export Trade Data In Excel Formats ************* // 
    const exportToExcel = () => {
        const exportableData = ExportableData();
        const ws = XLSX.utils.json_to_sheet(exportableData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Trades');
        XLSX.writeFile(wb, 'Fiat Withdrawal Details.xlsx');
    };


    // *********Export Trade Data In PDF Formats ************* // 
    const exportToPDF = () => {
        const exportableData = ExportableData();
        const doc = new jsPDF('landscape', 'mm', 'a4');
        doc.text('Fiat Withdrawal Details', 10, 10);
        const tableData = exportableData.map(item => Object.values(item));
        const tableHeaders = Object.keys(exportableData[0]); // Get keys from the first object
        doc.autoTable({
            head: [tableHeaders],
            body: tableData,
            startY: 20,
            theme: 'grid',
        });
        doc.save('Fiat Withdrawal Details.pdf');
    };

    // *********Export Trade Data In CSV Formats ************* // 
    const exportToCSV = () => {
        const exportableData = ExportableData();
        const csv = Papa.unparse(exportableData);
        const blob = new Blob([csv], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'Fiat Withdrawal Details.csv';
        link.click();
    };

    // ********* Rearrange Exportabel Data ********* //
    const ExportableData = () => {
        const modifiedArray = fiatWithdraw.map((item, index) => {
            const { updatedAt, user_id, __v, _id, currency_id, transaction_type, description, from_address, to_address, chain, short_name, mobileNumber, userObjId, createdAt, payment_type, ...rest } = item;
            const modifiedItem = {};
            for (const key in rest) {
                if (Object.hasOwnProperty.call(rest, key)) {
                    const modifiedKey = key.toUpperCase();
                    modifiedItem[modifiedKey] = rest[key];
                }
            }
            modifiedItem.CREATED_AT = moment(createdAt).format('DD/MM/YYYY hh:mm A');
            return modifiedItem;
        });
        return modifiedArray;

    }

    const HandleExport = (exportData) => {
        if (exportData === 'EXCEL') {
            exportToExcel()
        }
        else if (exportData === 'PDF') {
            exportToPDF()
        }
        else if (exportData === 'CSV') {
            exportToCSV()
        }
    };

    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"><i className="fa fa-feather"></i></div>
                                        Fiat Withdrawal
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="container-xl px-4 mt-n10">
                    <div className="card mb-4">
                        <div class="card-header">
                            Fiat Withdrawal Details
                            <div class="dropdown">
                                <button class="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fa fa-download me-3"></i>  Export
                                </button>
                                <div class="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                    <button type="button" onClick={() => { HandleExport('EXCEL') }} class="dropdown-item">Export as EXCEL</button>
                                    <button type="button" onClick={() => { HandleExport('CSV') }} class="dropdown-item">Export as CSV</button>
                                    <button type="button" onClick={() => { HandleExport('PDF') }} class="dropdown-item">Export as PDF</button>
                                </div>
                            </div>
                        </div>
                        {
                            fiatWithdraw.length === 0 ? <h6 className="ifnoData"><img alt="" src="/assets/img/no-data.png" /> <br />No Data Available</h6> :
                                <div className="" width="100%">
                                    <DataTableBase columns={columns} data={fiatWithdraw} />
                                </div>
                        }
                    </div>
                </div>
            </main>

            <div class="modal" id="bank_modal" tabindex="-1" aria-hidden="true" ><div class="modal-dialog  alert_modal" role="document"><div class="modal-content"><div class="modal-header"><h5 class="modal-title" id="exampleModalCenterTitle">
                Bank Details  </h5><button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
                <div class="modal-body">
                    <div class="w-100">
                        <div class="d-flex mb-2 align-items-center justify-content-between">
                            <strong>Bank Name</strong>
                            <span>{bankDetails?.bank_name}</span></div>
                    </div><div class="w-100">
                        <div class="d-flex mb-2 align-items-center justify-content-between">
                            <strong>Account Number</strong>
                            <span>{bankDetails?.account_number}</span>
                        </div>
                    </div>
                    <div class="w-100">
                        <div class="d-flex mb-2 align-items-center justify-content-between">
                            <strong>Account Holder Name</strong>
                            <span>{bankDetails?.account_holder_name}</span>
                        </div>
                    </div>
                    <div class="w-100">
                        <div class="d-flex mb-2 align-items-center justify-content-between">
                            <strong>Branch</strong>
                            <span>{bankDetails?.branch_name}</span>
                        </div>
                    </div>
                    <div class="w-100">
                        <div class="d-flex mb-2 align-items-center justify-content-between">
                            <strong>IFSC</strong>
                            <span>{bankDetails?.ifsc_code} </span>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </div>
        </div>
    )
}

export default FiatManagement;