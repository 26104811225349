import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage } from "../../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import moment from "moment";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import Papa from 'papaparse';
import 'jspdf-autotable';
import DataTableBase from "../../../customComponent/DataTable";

const FundsDManagement = () => {
    const [fundsDeposit, setFundsDeposit] = useState([]);

    const linkStatus = (row) => {
        return (
            row?.status === 'PENDING' ?
                <>
                    <button className="btn btn-success btn-sm me-2" onClick={() => handleWithdStatus(row?._id, 'COMPLETE')}>Approve</button>
                    <button className="btn btn-danger btn-sm mt-2 " onClick={() => handleWithdStatus(row?._id, 'CANCELLED')}>Denied</button>

                </> : <span className={`badge text-white rounded-pill ${row?.status === 'COMPLETE' ? "bg-success" : "bg-danger"} `}>{row?.status}</span>
        );
    };

    const columns = [
        { name: "Date", sortable: true, selector: row => moment(row?.createdAt).format("MMM Do YY") },
        { name: 'Email', wrap: true, selector: row => row.emailId, },
        { name: 'Name', compact: true, selector: row => row?.firstName + ' ' + row.lastName },
        { name: 'Chain', compact: true, selector: row => row.chain, },
        { name: 'Currency', compact: true, sortable: true, selector: row => row.currency, },
        { name: 'From Address', wrap: true, selector: row => row.from_address, },
        { name: 'To Wallet Address', wrap: true, selector: row => row.to_address, },
        { name: 'Transaction Hash', wrap: true, selector: row => row.transaction_hash, },
        { name: 'Amount', wrap: true, sortable: true, selector: row => row.amount, },
        { name: 'Status', selector: linkStatus },
    ];

    useEffect(() => {
        handleFundDeposit()
    }, []);

    const handleFundDeposit = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.getDepositFunds().then(async result => {
            LoaderHelper.loaderStatus(false);
            if (result.success) {
                try {
                    setFundsDeposit(result?.data?.reverse());
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
            }
        });
    };

    const handleWithdStatus = async (id, status) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.updateDepositStatus(id, status).then(async result => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                handleFundDeposit()
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message)
            }
        })
    };


    // *********Export Trade Data In Excel Formats ************* // 
    const exportToExcel = () => {
        const exportableData = ExportableData();
        const ws = XLSX.utils.json_to_sheet(exportableData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Trades');
        XLSX.writeFile(wb, 'Funds Deposit.xlsx');
    };


    // *********Export Trade Data In PDF Formats ************* // 
    const exportToPDF = () => {
        const exportableData = ExportableData();
        const doc = new jsPDF('landscape', 'mm', 'a4');
        doc.text('Funds Deposit', 10, 10);
        const tableData = exportableData.map(item => Object.values(item));
        const tableHeaders = Object.keys(exportableData[0]); // Get keys from the first object
        doc.autoTable({
            head: [tableHeaders],
            body: tableData,
            startY: 20,
            theme: 'grid',
        });
        doc.save('Funds Deposit.pdf');
    };

    // *********Export Trade Data In CSV Formats ************* // 
    const exportToCSV = () => {
        const exportableData = ExportableData();
        const csv = Papa.unparse(exportableData);
        const blob = new Blob([csv], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'Funds Deposit.csv';
        link.click();
    };

    // ********* Rearrange Exportabel Data ********* //
    const ExportableData = () => {
        const modifiedArray = fundsDeposit.map((item, index) => {
            const { updatedAt, user_id, __v, _id, currency_id, transaction_type, description, short_name, mobileNumber, userObjId, createdAt, ...rest } = item;
            const modifiedItem = {};
            for (const key in rest) {
                if (Object.hasOwnProperty.call(rest, key)) {
                    const modifiedKey = key.toUpperCase();
                    modifiedItem[modifiedKey] = rest[key];
                }
            }
            modifiedItem.CREATED_AT = moment(createdAt).format('DD/MM/YYYY hh:mm A');
            return modifiedItem;
        });
        return modifiedArray;

    }

    const HandleExport = (exportData) => {
        if (exportData === 'EXCEL') {
            exportToExcel()
        }
        else if (exportData === 'PDF') {
            exportToPDF()
        }
        else if (exportData === 'CSV') {
            exportToCSV()
        }
    };
    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"><i className="fa fa-dollar-sign"></i></div>
                                        Funds Deposit
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="container-xl px-4 mt-n10">
                    <div className="card mb-4">
                        <div class="card-header">
                            Funds Deposit
                            {fundsDeposit.length === 0 ? "" :
                                <div class="dropdown">
                                    <button class="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Export{" "}
                                    </button>
                                    <div class="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                        <button type="button" onClick={() => { HandleExport('EXCEL') }} class="dropdown-item">Export as EXCEL</button>
                                        <button type="button" onClick={() => { HandleExport('CSV') }} class="dropdown-item">Export as CSV</button>
                                        <button type="button" onClick={() => { HandleExport('PDF') }} class="dropdown-item">Export as PDF</button>
                                    </div>
                                </div>
                            }
                        </div>
                        {
                            fundsDeposit.length === 0 ? <h6 className="ifnoData"><img alt="" src="/assets/img/no-data.png" />  <br />No Data Available</h6> :
                                <div className="" width="100%">
                                    <DataTableBase columns={columns} data={fundsDeposit} />
                                </div>
                        }
                    </div>
                </div>
            </main>
        </div>
    )
}

export default FundsDManagement;