import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponent/CustomAlertMessage";
import AuthService from "../../../api/services/AuthService";
import { ApiConfig } from "../../../api/apiConfig/ApiConfig";
import TradeList from "../TradeList";
import { CSVLink } from "react-csv";
import moment from "moment";
import DataTableBase from "../../../customComponent/DataTable";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";

const TraderDetails = (props) => {
    const [tranactionData, setTranactionData] = useState([]);
    const [firstName, setFirstName] = useState('');
    const [email, setEmail] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [gender, setGender] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [verifiStatus, setVerifiStatus] = useState(props?.kycStatus);
    const [createdAt, setCreatedAt] = useState('');
    const [verificationDate, setVerificationDate] = useState('');
    const [profilePicture, setProfilePicture] = useState();
    const [lastName, setLastName] = useState('');
    const [selfie, setSelfie] = useState()
    const [frontImg, setFrontImg] = useState()
    const [backImg, setBackImg] = useState()
    const [panImg, setPanImg] = useState()
    const [showImage, setShowImage] = useState('');
    const [activeScreen, setActiveScreen] = useState('tradeList');
    const [kycType, setkycType] = useState();
    const [documentType, setdocumentType] = useState();
    const [bankDetails, setbankDetails] = useState([]);
    const [tds, settds] = useState();
    const [updatedTds, setUpdatedTds] = useState(props?.TDS);
    useEffect(() => {
        handleUserData();
        UserBankDetails();
    }, [])

    const handleUserData = async () => {
        await AuthService.allKycData(props.userId).then(async result => {
            if (result?.success) {
                try {
                    setFirstName(result.data?.first_name);
                    setEmail(result.data?.emailId);
                    setMobileNumber(result.data?.mobileNumber);
                    setGender(result.data?.gender);
                    setAddress(result.data?.address);
                    setCity(result.data?.city);
                    setState(result.data?.state);
                    setZipCode(result.data?.zip_code);
                    setCreatedAt(result.data?.createdAt);
                    setVerificationDate(result.data?.updatedAt);
                    setProfilePicture(result.data?.user_selfie);
                    setLastName(result.data?.last_name);
                    setSelfie(result.data?.user_selfie);
                    setFrontImg(result.data?.document_front_image);
                    setBackImg(result.data?.document_back_image);
                    setPanImg(result.data?.pancard_image);
                    setkycType(result.data?.kyc_type);
                    setdocumentType(result.data?.document_type);

                } catch (error) {
                    // alertErrorMessage(error);
                }
            } else {
                alertErrorMessage('No Data Found')
            }
        })
    }

    const columns = [
        { name: 'Transaction ID', selector: row => row._id, },
        { name: 'Amount', sortable: true, selector: row => row.amount, },
        { name: 'Coin Name', selector: row => row.coinSymbol, },
        { name: 'Type', selector: row => row.transType, },
        { name: 'Status', selector: row => row.status, },
    ]

    const handleTransferhistory = async () => {
        await AuthService.transferhistory(props.userId).then(async result => {
            if (result?.success) {
                try {
                    setTranactionData(result?.data);
                } catch (error) {
                    console.log('error', `${error}`);
                }
            } else {
                alertErrorMessage(result.message)
            }
        });
    }
    const UserBankDetails = async () => {
        await AuthService.getBankDetails(props.userId).then(async result => {
            if (result?.success) {
                try {
                    setbankDetails(result?.data)
                } catch (error) {
                    console.log('error', `${error}`);
                }
            } else {
                alertErrorMessage(result.message)
            }
        });
    }
    const VerifyBankDetails = async (id, status) => {
        await AuthService.verifyBankDetails(id, status).then(async result => {
            if (result?.success) {
                try {
                    UserBankDetails();
                } catch (error) {
                    console.log('error', `${error}`);
                }
            } else {
                alertErrorMessage(result.message)
            }
        });
    }
    const updateTds = async (id, tds) => {
        await AuthService.tdsPercentage(id, tds).then(async result => {
            if (result?.success) {
                try {
                    setUpdatedTds(tds)
                    settds('')
                    alertSuccessMessage(result?.message)
                } catch (error) {
                    console.log('error', `${error}`);
                }
            } else {
                alertErrorMessage(result.message)
            }
        });
    }

    const handleSubadminDetail = (img) => {
        setShowImage(img);
    }

    const [status, setstatus] = useState(props?.traderData?.master_account);
    const [makerFee, setMakerFee] = useState(props?.traderData?.maker_fee);
    const [takerFee, setTakerFee] = useState(props?.traderData?.taker_fee);

    const HandleStatus = (Status) => {
        setstatus(Status);
        if (!Status) {
            setMakerFee(0)
            setTakerFee(0)
        }
    };
    useEffect(() => {
        if (makerFee > 0 || takerFee > 0) {
            setstatus(true)
        } else {
            setMakerFee(0);
            setTakerFee(0);
            setstatus(false)
        }

    }, [makerFee, takerFee]);
    const HandleMasterAccount = async (userId, makerFee, takerFee, status) => {
        LoaderHelper?.loaderStatus(true)
        await AuthService.MasterAccount(userId, makerFee, takerFee, status).then(async result => {
            if (result?.success) {
                LoaderHelper?.loaderStatus(false)
                try {
                    alertSuccessMessage(result?.message)
                } catch (error) {
                    console.log('error', `${error}`);
                }
            } else {
                LoaderHelper?.loaderStatus(false)
                alertErrorMessage(result.message)
            }
        });
    }
    return (
        activeScreen === 'tradeList' ?
            <>
                <div id="layoutSidenav_content">
                    <main>
                        <form className="form-data" >
                            <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                                <div className="container-xl px-4">
                                    <div className="page-header-content pt-4">
                                        <div className="row align-items-center justify-content-between">
                                            <div className="col-auto mt-4">
                                                <h1 className="page-header-title">
                                                    <Link to="" className="page-header-icon" onClick={() => setActiveScreen('support')}><i className="fa fa-arrow-left" ></i></Link>
                                                    {firstName} {lastName}
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </header>
                            <div className="container-xl px-4 mt-n10" >
                                <div className="row" >

                                    <div className="col-xl-4">
                                        <div class="card mb-4 mb-xl-0">
                                            <div class="card-body py-5">
                                                {/* <!-- Profile picture image--> */}
                                                <div className="text-center">

                                                    {!profilePicture ? <img class="img-account-profile rounded-circle mb-4" src="/assets/img/illustrations/profiles/profile-5.png" alt="profilePicture" /> :

                                                        <img class="img-account-profile rounded-circle mb-4" src={ApiConfig?.uploadUrl + profilePicture} alt="profilePicture" />
                                                    }
                                                    <h3 className="fw-bolder fs-2 mb-0">
                                                        {firstName} {lastName}
                                                    </h3>
                                                    <div className=" text-gray">
                                                        <small>{email}</small>
                                                    </div>
                                                </div>
                                                <div class=" py-5 px-4 ">
                                                    <ul class="nav nav-pills flex-column" id="cardTab" role="tablist">
                                                        <li class="nav-item  ">
                                                            <a class="menu-link d-flex nav-link active" id="personal-pill" href="#personalPill" data-bs-toggle="tab" role="tab" aria-controls="personal" aria-selected="true">
                                                                <span class="menu-bullet"><span class="bullet"></span>
                                                                </span><span class="menu-title">  Personal Information </span>
                                                            </a>
                                                        </li>
                                                        <li class="nav-item  ">
                                                            <a class="menu-link d-flex nav-link" id="Verification-pill" href="#VerificationPill" data-bs-toggle="tab" role="tab" aria-controls="Verification" aria-selected="false"> <span class="menu-bullet"><span class="bullet"></span>
                                                            </span><span class="menu-title">  KYC Verification</span></a>
                                                        </li>
                                                        <li class="nav-item ">
                                                            <a class="menu-link d-flex nav-link" id="bankaccount-pill" href="#bankaccountPill" data-bs-toggle="tab" role="tab" aria-controls="example" aria-selected="false"> <span class="menu-bullet"><span class="bullet"></span>
                                                            </span><span class="menu-title">  Bank Account Details</span></a>
                                                        </li>
                                                        <li class="nav-item ">
                                                            <a class="menu-link d-flex nav-link" id="Transitions-pill" href="#TransitionsPill" data-bs-toggle="tab" role="tab" aria-controls="example" aria-selected="false"> <span class="menu-bullet"><span class="bullet"></span>
                                                            </span><span class="menu-title">  User Transaction</span></a>
                                                        </li>
                                                        {/* <li class="nav-item ">
                                                            <a class="menu-link d-flex nav-link" id="TDS-pill" href="#TDSPill" data-bs-toggle="tab" role="tab" aria-controls="example" aria-selected="false"> <span class="menu-bullet"><span class="bullet"></span>
                                                            </span><span class="menu-title"> TDS</span></a>
                                                        </li> */}
                                                        {/* <li class="nav-item ">
                                                            <a class="menu-link d-flex nav-link" id="Transitions-pill" href="#masterAdmin" data-bs-toggle="tab" role="tab" aria-controls="example" aria-selected="false"> <span class="menu-bullet"><span class="bullet"></span>
                                                            </span><span class="menu-title">  Master Trader</span></a>
                                                        </li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-8" >
                                        <div class="tab-content tab-content-card" id="cardPillContent">
                                            {/* personal INformation */}
                                            <div class="tab-pane card show active" id="personalPill" role="tabpanel" aria-labelledby="personal-pill">
                                                {/* <!-- Profile picture help block--> */}
                                                <div class="card-header">
                                                    Personal Information
                                                    <div>
                                                    <span className="col-3 ms-5 " >Verification Status: </span>
                                                    {verifiStatus === 2 ?
                                                        <strong class=" col text-success ">Verified</strong>
                                                        : verifiStatus === 3 ? <strong class=" col text-danger ">Rejected</strong> : verifiStatus === 1 ? <strong class=" col text-warning ">Pending</strong> : <strong class=" col text-warning ">KYC Not Submitted</strong>
                                                    }
                                                    </div>
                                                </div>




                                                <div className="card-body" >
                                                    <div className="profile_data py-4 px-4" >

                                                        <div className="row" > <span className="col-3" >Name: </span><strong className="col text-dark ">{firstName}</strong></div>
                                                        <hr class="my-3" />
                                                        <div className="row" > <span className="col-3" >Email:</span> <strong class=" col">{email}</strong></div>
                                                        <hr class="my-3" />
                                                        <div className="row" > <span className="col-3" >Mobile Number:</span> <strong class="col"> {mobileNumber} </strong></div>
                                                        <hr class="my-3" />
                                                        <div className="row" > <span className="col-3 " >Address:</span> <strong class="col"> {address} </strong></div>
                                                        <hr class="my-3" />
                                                        <div className="row" > <span className="col-3" >City:</span> <strong class=" col"> {city} </strong></div>
                                                        <hr class="my-3" />
                                                        <div className="row" > <span className="col-3" >State:</span> <strong class=" col"> {state}  </strong></div>
                                                        <hr class="my-3" />
                                                        <div className="row" > <span className="col-3" >Zip Code:</span> <strong class=" col"> {zipCode} </strong></div>
                                                    </div>
                                                </div>
                                                {/* <!-- Profile picture upload button--> */}
                                            </div>
                                            {/* Personal Information */}

                                            {/* KYC Verification */}
                                            <div class="tab-pane card" id="VerificationPill" role="tabpanel" aria-labelledby="Verification-pill">
                                                <div class="card-header">KYC Verification Details</div>
                                                <div className="card-body" >
                                                    <div className="profile_data py-4 px-4" >
                                                        <div className="row" >
                                                            <span className="col-3" >Verification Status: </span>
                                                            {verifiStatus === 2 ?
                                                                <strong class=" col text-success  ">Verified</strong>
                                                                : verifiStatus === 3 ? <strong class=" col text-danger ">Rejected</strong> : verifiStatus === 1 ? <strong class=" col text-warning ">Pending</strong> : <strong class=" col text-warning ">KYC Not Submitted</strong>
                                                            }

                                                        </div>
                                                        <hr class="my-3" />
                                                        <div className="row" > <span className="col-3" >Registration Date:</span> <strong class=" col">{createdAt && moment(createdAt).format('Do MMMM YYYY')}</strong></div>
                                                        <hr class="my-3" />
                                                        <div className="row" > <span className="col-3" >Verification Date:</span> <strong class=" col">{verificationDate && moment(verificationDate).format('Do MMMM YYYY')}  </strong></div>
                                                        <hr class="my-3" />
                                                        <div className="row" > <span className="col-3" >KYC Type:</span> <strong class=" col"> {kycType} </strong></div>
                                                        <hr class="my-3" />
                                                        <div className="row" > <span className="col-3" >Document Type:</span> <strong class=" col"> {documentType} </strong></div>
                                                        <hr class="my-3" />

                                                        <div class="row">
                                                            <span className="mb-4 col-12" >Document Verification Images:</span>
                                                            <div class="col-6  mb-3">
                                                                <div class="doc_img">
                                                                    <div class="row mb-3">
                                                                        <div class="col">Other Identity <small> (Front) </small>
                                                                        </div>
                                                                    </div>

                                                                    {!frontImg ?
                                                                        <div class="ratio ratio-16x9">
                                                                            <img alt="" class="w-100" src="/assets/img/illustrations/profiles/nophoto.png" />
                                                                        </div> :

                                                                        <div class="ratio ratio-16x9">
                                                                            <img src={ApiConfig?.uploadUrl + frontImg} alt="" class="w-100 cursor_pointer" data-bs-toggle="modal" data-bs-target="#edit_modal" onClick={() => handleSubadminDetail(ApiConfig?.uploadUrl + frontImg)} />
                                                                        </div>
                                                                    }

                                                                </div>
                                                            </div>
                                                            <div class="col-6 mb-3">
                                                                <div class="doc_img">
                                                                    <div class="row mb-3">
                                                                        <div class="col"> Other Identity <small> (Back) </small> </div>
                                                                    </div>
                                                                    {!backImg ?
                                                                        <div class="ratio ratio-16x9">
                                                                            <img alt="" class="w-100" src="/assets/img/illustrations/profiles/nophoto.png" />
                                                                        </div> :
                                                                        <div class="ratio ratio-16x9">
                                                                            <img src={ApiConfig?.uploadUrl + backImg} alt="" class="w-100 cursor_pointer" data-bs-toggle="modal" data-bs-target="#edit_modal" onClick={() => handleSubadminDetail(ApiConfig?.uploadUrl + backImg)} />
                                                                        </div>
                                                                    }

                                                                </div>
                                                            </div>
                                                            <div class="col-6 mb-3">
                                                                <div class="doc_img">
                                                                    <div class="row mb-3">
                                                                        <div class="col"> Other Identity </div>
                                                                    </div>
                                                                    {!panImg ?
                                                                        <div class="ratio ratio-16x9">
                                                                            <img alt="" class="w-100" src="/assets/img/illustrations/profiles/nophoto.png" />
                                                                        </div> :
                                                                        <div class="ratio ratio-16x9">
                                                                            <img src={ApiConfig?.uploadUrl + panImg} alt="" class="w-100 cursor_pointer" data-bs-toggle="modal" data-bs-target="#edit_modal" onClick={() => handleSubadminDetail(ApiConfig?.uploadUrl + panImg)} />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div class="col-6 mb-3">
                                                                <div class="doc_img">
                                                                    <div class="row mb-3">
                                                                        <div class="col"> Selfie </div>
                                                                    </div>
                                                                    {!selfie ?
                                                                        <div class="ratio ratio-16x9">
                                                                            <img alt="" class="w-100" src="/assets/img/illustrations/profiles/nophoto.png" />
                                                                        </div> :
                                                                        <div class="ratio ratio-16x9">
                                                                            <img src={ApiConfig?.uploadUrl + selfie} alt="" class="w-100 cursor_pointer" data-bs-toggle="modal" data-bs-target="#edit_modal" onClick={() => handleSubadminDetail(ApiConfig?.uploadUrl + selfie)} />
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* KYC Verification */}

                                            {/* Bank Account Details*/}
                                            <div class="tab-pane card" id="bankaccountPill" role="tabpanel" aria-labelledby="bankaccount-pill">
                                                <div class="card-header">Bank Account Details</div>
                                                {bankDetails?.length > 0 ? bankDetails?.map((item) => {
                                                    return (<>
                                                        <div className="profile_data py-1 px-2" >
                                                            <div className=" d-flex justify-content-end" >
                                                                {item?.verified === 0 ? <>
                                                                    <button className="btn-success  btn btn-sm" type="button" style={{ marginLeft: "20px" }} onClick={() => VerifyBankDetails(item?._id, '1')}>Approve
                                                                    </button>
                                                                    <button className="btn-danger  btn btn-sm" type="button" style={{ marginLeft: "20px" }} onClick={() => VerifyBankDetails(item?._id, '2')}>Reject
                                                                    </button></> : item?.verified === 1 ? <span className="col-3 text-success" >Approved </span> : <span className="col-3 text-danger" >Rejected </span>}

                                                            </div>
                                                            <div className="row" >
                                                                <span className="col-3" >Bank Name: </span>
                                                                <strong class=" col text-success ">  {item?.bank_name}  </strong>
                                                            </div>
                                                            <div className="row" > <span className="col-3" >Bank Account Number:</span> <strong class=" col">{item?.account_number}</strong></div>

                                                            <div className="row" > <span className="col-3" >Account Holder Name:</span> <strong class=" col"> {item?.account_holder_name} </strong></div>

                                                            <div className="row" > <span className="col-3" >Branch Name:</span> <strong class=" col"> {item?.branch_name} </strong></div>

                                                            <div className="row" > <span className="col-3" >IFSC Code:</span> <strong class=" col"> {item?.ifsc_code} </strong></div>
                                                        </div>


                                                        <hr /></>)

                                                })
                                                    : <h6 className="ifnoData">
                                                        <img src="/assets/img/no-data.png" alt="" /> <br /> No Data
                                                        Available
                                                    </h6>}
                                            </div>

                                            {/* User Transitions*/}
                                            <div class="tab-pane card" id="TransitionsPill" role="tabpanel" aria-labelledby="Transitions-pill">
                                                <div class="card-header">User Transition
                                                    {
                                                        tranactionData.length === 0 ? "" :
                                                            <div class="dropdown">
                                                                <button class="btn btn-dark btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Export</button>
                                                                <div class="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                                                    <CSVLink data={tranactionData} class="dropdown-item">Export as CSV</CSVLink>
                                                                </div>
                                                            </div>
                                                    }

                                                </div>
                                                <>
                                                    {
                                                        tranactionData.length === 0 ? <h6 className="ifnoData"> <img alt="" src="/assets/img/no-data.png" /> <br /> No Data Available </h6> :
                                                            <div className="card-body" >
                                                                <form className="row">
                                                                    <div className="col-12" >
                                                                        <table className="" width="100%" >
                                                                            <DataTableBase columns={columns} data={tranactionData} />

                                                                        </table>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                    }
                                                </>
                                            </div>
                                            {/*TDS Module*/}
                                            <div class="tab-pane card" id="TDSPill" role="tabpanel" aria-labelledby="TDS-pill">
                                                <div class="card-header">User Tds</div>
                                                <>
                                                    <div className="profile_data  py-5 px-4" >

                                                        <div className="row" >
                                                            <span className="col-3" >TDS: </span>

                                                            <strong class=" col text-success  ">{updatedTds}</strong>


                                                        </div>
                                                        <hr class="my-3" />
                                                        <div className="row" >
                                                            <div className="col-6">
                                                                <label className="small mb-1" for="Maker">TDS </label>
                                                                <input type="number" className="form-control  form-control-solid" id="TDS" placeholder="TDS" name="TDS" value={tds} onChange={(e) => settds(e.target.value)} onWheel={(e) => e.target.blur()} />
                                                            </div>
                                                        </div>
                                                        <hr class="my-3" />
                                                        <div className="row" >

                                                            <button type='button' className='btn  btn me-2 btn-success w-50' onClick={() => updateTds(props?.userId, tds)}  >Update</button>  </div>


                                                    </div>


                                                    <hr /></>

                                            </div>

                                            {/* Master Admin */}
                                            <div class="tab-pane card" id="masterAdmin" role="tabpanel" aria-labelledby="bankaccount-pill">
                                                <div class="card-header">Master Trader</div>

                                                <div className="profile_data py-5 px-4" >
                                                    <div className="row" >
                                                        <label className="small mb-1" for="Maker">Maker Fee </label>
                                                        <input type="number" className="form-control  form-control-solid" id="Maker" placeholder="Maker Fee" name="firstName" value={makerFee} onChange={(e) => setMakerFee(e.target.value)} onWheel={(e) => e.target.blur()} />
                                                    </div>
                                                    <hr class="my-3" />
                                                    <div className="row" >
                                                        <label className="small mb-1" for="taker">Taker Fee </label>
                                                        <input type="number" className="form-control  form-control-solid" id="taker" placeholder="Taker Fee " name="firstName" value={takerFee} onChange={(e) => setTakerFee(e.target.value)} onWheel={(e) => e.target.blur()} /></div>
                                                    <hr class="my-3" />
                                                    <div className="row" > <label className="small mb-1" for="taker">Status </label><div>
                                                        <button type="button" className={`btn  btn-sm  me-2 ${status ? 'btn-success' : 'btn-outline-success'}`} onClick={() => HandleStatus(true)} >Active</button>
                                                        <button type="button" className={`btn  btn-sm  me-2 ${status ? 'btn-outline-danger' : 'btn-danger'}`} onClick={() => HandleStatus(false)}  >Inactive</button></div>  </div>
                                                    <hr class="my-3" />
                                                    <div className="row" >
                                                        <button type="button" className={`btn  btN me-2 btn-success`} onClick={() => HandleMasterAccount(props.userId, makerFee, takerFee, status)}  >Submit</button>  </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </main>
                    {/* Trade Image Detail */}
                    <div class="modal image_modal" id="edit_modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                        <div class="modal-dialog  alert_modal modal-lg" role="document">
                            <div class="modal-content">
                                <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                                <div class="ratio ratio-16x9">
                                    <img src={showImage} class="w-100 cc_modal_img" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Trade Image Detail  */}
                </div >
            </>
            : <TradeList />

    )


}

export default TraderDetails;